import React, { createContext, useContext, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';

interface ICliente {
  id?: number;
  nome: string;
  email: string;
  telefone: string;
  endereco?: string;
  informacoesAdicionais?: string;
  senha?: string;
  status?: string;
}

interface ClienteContextType {
  clients: ICliente[];
  addClient: (data: ICliente) => Promise<void>;
  editClient: (id: number, data: ICliente) => Promise<void>;
  deleteClient: (id: number) => Promise<void>;
}

const ClienteContext = createContext<ClienteContextType | undefined>(undefined);

export function ClienteProvider({ children }) {
  const [clients, setClients] = useState<ICliente[]>([]);

  async function getClients() {
    const response = await axiosInstance.get('/cliente/');
    setClients(response.data);
  }

  async function addClient(data: ICliente) {
    await axiosInstance.post('/cliente/', data);
    getClients();
  }

  async function editClient(id: number, data: ICliente) {
    await axiosInstance.put(`/cliente/${id}`, data);
    getClients();
  }

  async function deleteClient(id: number) {
    await axiosInstance.delete(`/cliente/${id}`);
    getClients();
  }

  React.useEffect(() => {
    getClients();
  }, []);

  return (
    <ClienteContext.Provider
      value={{ clients, addClient, editClient, deleteClient }}
    >
      {children}
    </ClienteContext.Provider>
  );
}

export function useCliente() {
  const context = useContext(ClienteContext);
  if (!context) {
    throw new Error('useCliente deve ser usado dentro de um ClienteProvider');
  }
  return context;
}
