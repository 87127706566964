import React, { useState } from 'react';
import {
  AddButton,
  CloseButton,
  Container,
  ContainerFlex,
  FirstColumn,
  Form,
  Header,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabContainer,
  TextArea,
  TextField,
  NextEvents,
} from './style';

import Calendar from './components/Calendar/Calendar';
import BigCalendar from './components/BigCalendar/BigCalendar';
import { addMinutes, format, parse } from 'date-fns';

import { useAgendamento } from '../../context/AgendamentoContext/AgendamentoContext';
import ModalDelete from '../../hooks/ModalDelete/ModalDelete';
import { toast } from 'react-toastify';

const Eventos: React.FC = () => {
  const {
    agendamentos,
    profissionais,
    unidades,
    horarios,
    servicos,
    clientes,
    especialidades,

    createAgendamento,
    updateAgendamento,
    deleteAgendamento,
  } = useAgendamento();

  const [dateSelected, setDateSelected] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('addClient');

  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [selectedProfissional, setSelectedProfissional] = useState('');
  const [selectedHorario, setSelectedHorario] = useState('');
  const [selectedCliente, setSelectedCliente] = useState('');
  const [selectedUnidade, setSelectedUnidade] = useState('');
  const [selectedServico, setSelectedServico] = useState('');
  const [selectedEspecialidade, setSelectedEspecialidade] = useState('');
  const [informacoesAdicionais, setInformacoesAdicionais] = useState('');

  const openModal = () => {
    resetForm();
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  const handleDateClick = (date: Date) => {
    setDateSelected(date);
  };

  function resetForm() {
    setSelectedEvent(null);
    setSelectedProfissional('');
    setSelectedHorario('');
    setSelectedCliente('');
    setSelectedUnidade('');
    setSelectedServico('');
    setSelectedEspecialidade('');
    setInformacoesAdicionais('');
    setDateSelected(new Date());
  }

  // ---- Submeter novo agendamento ----
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !selectedProfissional ||
      !selectedHorario ||
      !selectedCliente ||
      !selectedUnidade ||
      !selectedServico
    ) {
      console.error('Preencha todos os campos obrigatórios!');
      return;
    }
    try {
      await createAgendamento({
        data_hora: dateSelected.toISOString(),
        cliente_id: parseInt(selectedCliente),
        profissional_id: parseInt(selectedProfissional),
        servico_id: parseInt(selectedServico),
        unidade_id: parseInt(selectedUnidade),
        especialidade_id: parseInt(selectedEspecialidade),
        hora_inicio: selectedHorario,
        hora_fim: addMinutes(parse(selectedHorario, 'HH:mm', new Date()), 30)
          .toTimeString()
          .slice(0, 5),
        observacoes: informacoesAdicionais || '',
        status: 'pendente',
      });
      closeModal();
      toast.success('Evento criado com sucesso!');
    } catch (error) {
      console.error('Erro ao criar agendamento:', error);
      toast.error('Ocorreu um erro ao criar o evento');
    }
  };

  // ---- Abrir em modo edição ----
  const openModalEdit = (eventData: any) => {
    setSelectedEvent(eventData);
    // Ajustar states
    setDateSelected(new Date(eventData.data_hora));

    if (eventData.hora_inicio) {
      const hora = eventData.hora_inicio.slice(0, 5); // 'HH:mm'
      setSelectedHorario(hora);
    }

    setSelectedProfissional(eventData.profissional_id?.toString() || '');
    setSelectedCliente(eventData.cliente_id?.toString() || '');
    setSelectedServico(eventData.servico_id?.toString() || '');
    setSelectedUnidade(eventData.unidade_id?.toString() || '');

    setSelectedEspecialidade(eventData.especialidade_id?.toString() || '');
    setInformacoesAdicionais(eventData.observacoes || '');

    setIsModalOpen(true);
  };

  // ---- Atualizar ----
  const handleUpdate = async () => {
    if (!selectedEvent) return;
    try {
      await updateAgendamento(selectedEvent.id, {
        data_hora: dateSelected.toISOString(),
        cliente_id: parseInt(selectedCliente),
        profissional_id: parseInt(selectedProfissional),
        servico_id: parseInt(selectedServico),
        unidade_id: parseInt(selectedUnidade),
        especialidade_id: parseInt(selectedEspecialidade),
        hora_inicio: selectedHorario,
        hora_fim: addMinutes(parse(selectedHorario, 'HH:mm', new Date()), 30)
          .toTimeString()
          .slice(0, 5),
        observacoes: informacoesAdicionais || '',
        status: selectedEvent.status || 'pendente',
      });
      closeModal();
      toast.success('Evento atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar evento:', error);
    }
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [message, setMessage] = useState('');

  const openDeleteModal = () => {
    setMessage(
      `Deseja excluir o evento ${format(
        selectedEvent.data_hora,
        'dd/MM/yyyy HH:mm'
      )}, com o profissional ${selectedEvent.profissional.nome}?`
    );

    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    handleDelete();

    setIsDeleteModalOpen(false);
  };

  // ---- Deletar ----
  const handleDelete = async () => {
    if (!selectedEvent) return;
    try {
      await deleteAgendamento(selectedEvent.id);
      toast.success('Evento deletado com sucesso!');
      closeModal();
    } catch (error) {
      console.error('Erro ao deletar evento:', error);
      toast.error('Erro ao deletar o evento.');
    }
  };

  return (
    <Container>
      <Header>
        <h1>Eventos</h1>
        <AddButton onClick={openModal}>Adicionar evento +</AddButton>
      </Header>

      <ContainerFlex>
        <FirstColumn>
          <Calendar selectedDate={dateSelected} onDateClick={handleDateClick} />
          <br />
          <p>Eventos Próximos</p>
          <NextEvents>
            {agendamentos && agendamentos.length > 0 ? (
              agendamentos.map((agen) => (
                <div key={agen.id} onClick={() => openModalEdit(agen)}>
                  <p>
                    {agen.cliente?.nome} - {agen.servico?.nome} -{' '}
                    {agen.hora_inicio}
                  </p>
                </div>
              ))
            ) : (
              <p>Nenhum evento próximo</p>
            )}
          </NextEvents>
        </FirstColumn>

        <FirstColumn>
          <BigCalendar agendamentos={agendamentos} />
        </FirstColumn>
      </ContainerFlex>

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <TabContainer>
                <Tab
                  active={activeTab === 'addClient'}
                  onClick={() => setActiveTab('addClient')}
                >
                  {selectedEvent ? 'Editar evento' : 'Adicionar evento'}
                </Tab>
              </TabContainer>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>
            <ModalBody>
              {activeTab === 'addClient' && (
                <Form>
                  <ContainerFlex>
                    <div>
                      <label>Profissional</label>
                      <select
                        value={selectedProfissional}
                        onChange={(e) =>
                          setSelectedProfissional(e.target.value)
                        }
                      >
                        <option value="">Selecione o profissional</option>
                        {profissionais.map((prof) => (
                          <option key={prof.id} value={prof.id}>
                            {prof.nome}
                          </option>
                        ))}
                      </select>

                      <label>Data</label>
                      <TextField
                        type="date"
                        value={format(dateSelected, 'yyyy-MM-dd')}
                        onChange={(e) => {
                          const [year, month, day] = e.target.value
                            .split('-')
                            .map(Number);
                          setDateSelected(new Date(year, month - 1, day));
                        }}
                      />

                      <label>Horários Disponíveis</label>
                      <select
                        value={selectedHorario}
                        onChange={(e) => setSelectedHorario(e.target.value)}
                      >
                        <option value="">Selecione o horário</option>
                        {horarios.map((h) => (
                          <option key={h} value={h}>
                            {h}
                          </option>
                        ))}
                      </select>

                      <label>Serviço</label>
                      <select
                        value={selectedServico}
                        onChange={(e) => setSelectedServico(e.target.value)}
                      >
                        <option value="">Selecione o serviço</option>
                        {servicos.map((serv) => (
                          <option key={serv.id} value={serv.id}>
                            {serv.nome}
                          </option>
                        ))}
                      </select>

                      <label>Cliente</label>
                      <select
                        value={selectedCliente}
                        onChange={(e) => setSelectedCliente(e.target.value)}
                      >
                        <option value="">Selecione o cliente</option>
                        {clientes.map((cli) => (
                          <option key={cli.id} value={cli.id}>
                            {cli.nome}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label>Especialidade</label>
                      <select
                        value={selectedEspecialidade}
                        onChange={(e) =>
                          setSelectedEspecialidade(e.target.value)
                        }
                      >
                        <option value="">Selecione a especialidade</option>
                        {especialidades.map((esp) => (
                          <option key={esp.id} value={esp.id}>
                            {esp.nome}
                          </option>
                        ))}
                      </select>

                      <label>Unidade</label>
                      <select
                        value={selectedUnidade}
                        onChange={(e) => setSelectedUnidade(e.target.value)}
                      >
                        <option value="">Selecione a unidade</option>
                        {unidades.map((uni) => (
                          <option key={uni.id} value={uni.id}>
                            {uni.nome}
                          </option>
                        ))}
                      </select>

                      <label>Informações adicionais</label>
                      <TextArea
                        placeholder="Adicione informações extras..."
                        name="informacoesAdicionais"
                        value={informacoesAdicionais}
                        onChange={(e) =>
                          setInformacoesAdicionais(e.target.value)
                        }
                      />
                    </div>
                  </ContainerFlex>
                  <br />
                  <ModalFooter>
                    {selectedEvent ? (
                      <>
                        <button type="button" onClick={handleUpdate}>
                          Editar evento
                        </button>
                        <button
                          type="button"
                          className="button-delete"
                          onClick={openDeleteModal}
                        >
                          Deletar evento
                        </button>
                      </>
                    ) : (
                      <button type="submit" onClick={handleSubmit}>
                        Adicionar evento
                      </button>
                    )}
                    <button type="button" onClick={closeModal}>
                      Cancelar
                    </button>
                  </ModalFooter>
                </Form>
              )}
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      )}

      <ModalDelete
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        title="Excluir Evento"
        message={message}
      />
    </Container>
  );
};

export default Eventos;
