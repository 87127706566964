import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../../../assets/logo.svg';
import {
  ContainerListaLink,
  ContainerListaLogin,
  ContainerListaLogo,
  ContainerNavbar,
  HamburgerMenu,
  MenuIcon,
  CloseIcon,
  MobileMenuContainer,
} from './style';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const links = [
    { path: '/', text: 'Home' },
    { path: '#oportunity', text: 'Serviços' },
    { path: '#who', text: 'Quem somos' },
    { path: 'https://wa.link/h5d1k4', text: 'Contato' },
    { path: '/login', text: 'Area do Cliente' },
  ];

  const scrollToSection = (id: any) => {
    if (id.includes('#')) {
      const path = id.split('#')[1];

      const element = document.getElementById(path);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <ContainerNavbar>
      <ContainerListaLogo>
        <li>
          <img title="Logo" src={logo} />
        </li>
      </ContainerListaLogo>
      <HamburgerMenu onClick={toggleMenu}>
        <MenuIcon isOpen={isOpen} />
      </HamburgerMenu>
      <ContainerListaLink isOpen={isOpen}>
        {links.map((link, index) => (
          <li
            key={index}
            className={location.pathname === link.path ? 'active' : ''}
          >
            <Link
              to={link.path}
              onClick={() => {
                setIsOpen(false);
                scrollToSection(link.path);
              }}
            >
              {link.text}
            </Link>
          </li>
        ))}
        <li>
          {/* <Link to={'/login'}>
            <button>Área do Cliente</button>
          </Link> */}
        </li>
      </ContainerListaLink>
      <ContainerListaLogin>
        <li>
          {/* <Link to={'/login'}>
            <button>Área do Cliente</button>
          </Link> */}
        </li>
      </ContainerListaLogin>
      <MobileMenuContainer isOpen={isOpen}>
        <CloseIcon onClick={toggleMenu} />
        <li>
          <img title="Logo" src={logo} />
        </li>
        {links.map((link, index) => (
          <li
            key={index}
            className={location.pathname === link.path ? 'active' : ''}
          >
            <Link
              to={link.path}
              onClick={() => {
                setIsOpen(false);
                scrollToSection(link.path);
              }}
            >
              {link.text}
            </Link>
          </li>
        ))}
        <li>
          {/*   <Link to={'/login'}>
            <button>Área do Cliente</button>
          </Link>
         */}
        </li>
      </MobileMenuContainer>
    </ContainerNavbar>
  );
};

export default Navbar;
