import React from 'react';
import {
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalFooter,
} from './style';
import { Tab, TabContainer, ContainerFlex } from './style';

interface ModalDeleteProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
}

const ModalDelete: React.FC<ModalDeleteProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title = 'Deletar',
  message = 'Tem certeza que deseja excluir este item?',
}) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <TabContainer>
            <Tab active>{title}</Tab>
          </TabContainer>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ModalHeader>
        <ModalBody>
          <ContainerFlex>
            <p>{message}</p>
          </ContainerFlex>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={onClose}>
            Cancelar
          </button>
          <button type="button" onClick={onConfirm}>
            Excluir
          </button>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalDelete;
