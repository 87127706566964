import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  FilterBar,
  AddButton,
  Table,
  Pagination,
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContainer,
  Tab,
  Form,
  TextField,
  TextArea,
} from './style';

import lapis from './assets/lapis.png';

import { useGestaoEquipe } from '../../../context/GestaoEquipe/GestaoEquipeContext';
import { toast } from 'react-toastify';
import ModalDelete from '../../../hooks/ModalDelete/ModalDelete';

const TabelaEspecialidadeGestaoEquipe = () => {
  const {
    especialidades,
    getEspecialidades,
    handleAddEspecialidade,
    handleEditEspecialidade,
    handleDeleteEspecialidade,
  } = useGestaoEquipe();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('addClient');

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [descricao, setDescricao] = useState('');

  useEffect(() => {
    getEspecialidades();
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === 'nome') setName(value);
    if (name === 'descricao') setDescricao(value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await handleAddEspecialidade({ nome: name, descricao });
      closeModal();

      toast.success('Especialidade criada com sucesso!');

      setName('');
      setDescricao('');
      setId('');
    } catch (error) {
      console.error('Erro ao adicionar especialidade:', error);
    }
  };

  const openModalEdit = (especialidadeId: string) => {
    const especialidadeEdit = especialidades.find(
      (e) => e.id === especialidadeId
    );
    if (!especialidadeEdit) return;

    setId(especialidadeEdit.id);
    setName(especialidadeEdit.nome);
    setDescricao(especialidadeEdit.descricao || '');
    openModal();
  };

  const handleSubmitEdit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await handleEditEspecialidade(id, { nome: name, descricao });
      closeModal();

      toast.success('Especialidade atualizada com sucesso!');

      setName('');
      setDescricao('');
      setId('');
    } catch (error) {
      console.error('Erro ao editar especialidade:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await handleDeleteEspecialidade(id);
      closeModal();

      toast.success('Especialidade deletada com sucesso!');

      setName('');
      setDescricao('');
      setId('');
    } catch (error) {
      console.error('Erro ao deletar especialidade:', error);
    }
  };

  /* ======> DELTE <====== */

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [message, setMessage] = useState('');

  const openDeleteModal = () => {
    setMessage(`Deseja excluir a especialidade ${name} ?`);

    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    handleDelete();

    setIsDeleteModalOpen(false);
  };

  return (
    <Container>
      <Header>
        <div>
          <h3>Especialidade</h3>
          <br />
          <p>
            A especialidade refere-se ao papel específico que os profissionais
            desempenham dentro da empresa.
          </p>
        </div>
      </Header>

      <Header>
        <FilterBar>
          <p>
            <b>Especialidades Cadastradas:</b>
          </p>
        </FilterBar>

        <AddButton
          onClick={() => {
            setName('');
            setDescricao('');
            setId('');
            openModal();
          }}
        >
          Adicionar +
        </AddButton>
      </Header>

      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {especialidades.length === 0 ? (
            <tr>
              <td colSpan={3}>
                <h2>Nenhuma especialidade encontrada.</h2>
              </td>
            </tr>
          ) : (
            especialidades.map((esp) => (
              <tr key={esp.id}>
                <td>{esp.nome}</td>
                <td>{esp.descricao}</td>
                <td>
                  <button onClick={() => openModalEdit(esp.id)}>
                    <img src={lapis} alt="Editar" />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination>
        <span>1-2 de 2</span>
        <div>
          <span>Itens por página: 15</span>
          <button>{'<'}</button>
          <span>1 / 2</span>
          <button>{'>'}</button>
        </div>
      </Pagination>

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <TabContainer>
                <Tab
                  active={activeTab === 'addClient'}
                  onClick={() => setActiveTab('addClient')}
                >
                  Adicionar Especialidade
                </Tab>
              </TabContainer>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>
            <ModalBody>
              {activeTab === 'addClient' && (
                <Form>
                  <div>
                    <label>Nome da Especialidade</label>
                    <TextField
                      onChange={handleChange}
                      value={name}
                      name="nome"
                      type="text"
                      placeholder="Nome da Especialidade"
                    />
                  </div>
                  <div>
                    <label>Descrição da Especialidade</label>
                    <TextArea
                      value={descricao}
                      name="descricao"
                      onChange={handleChange}
                      placeholder="Adicione informações extras..."
                    />
                  </div>
                </Form>
              )}
            </ModalBody>
            <ModalFooter>
              {id !== '' ? (
                <>
                  <button onClick={handleSubmitEdit}>
                    Editar Especialidade
                  </button>
                  <button className="button-delete" onClick={openDeleteModal}>
                    Deletar Especialidade
                  </button>
                  <br />
                </>
              ) : (
                <button onClick={handleSubmit}>Adicionar Especialidade</button>
              )}
              <button onClick={closeModal}>Cancelar</button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}

      <ModalDelete
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        title="Excluir Especialidade"
        message={message}
      />
    </Container>
  );
};

export default TabelaEspecialidadeGestaoEquipe;
