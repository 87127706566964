// src/context/AgendamentoContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { format, isBefore, addMinutes } from 'date-fns';
import { parse } from 'date-fns';

interface IProfissional {
  id: number;
  nome: string;
}
interface IUnidade {
  id: number;
  nome: string;
}
interface ICliente {
  id: number;
  nome: string;
}
interface IServico {
  id: number;
  nome: string;
}
interface IEspecialidade {
  id: number;
  nome: string;
}

interface IAgendamento {
  id: number;
  data_hora: string;
  hora_inicio: string;
  hora_fim: string;
  cliente_id: number;
  profissional_id: number;
  servico_id: number;
  unidade_id: number;
  especialidade_id?: number;
  observacoes?: string;
  status?: string;

  cliente: ICliente;
  profissional: IProfissional;
  servico: IServico;
  unidade: IUnidade;
  especialidade: IEspecialidade;
}

interface AgendamentoContextProps {
  agendamentos: IAgendamento[];
  profissionais: IProfissional[];
  unidades: IUnidade[];
  horarios: string[];
  servicos: IServico[];
  clientes: ICliente[];
  especialidades: IEspecialidade[];

  getAgendamentos: () => Promise<void>;
  getProfissional: () => Promise<void>;
  getUnidades: () => Promise<void>;
  getHorarios: () => void;
  getServicos: () => Promise<void>;
  getClientes: () => Promise<void>;
  getEspecialidades: () => Promise<void>;

  createAgendamento: (data: Partial<IAgendamento>) => Promise<void>;
  updateAgendamento: (id: number, data: Partial<IAgendamento>) => Promise<void>;
  deleteAgendamento: (id: number) => Promise<void>;

  gerarHorariosDisponiveis: (
    inicio: string,
    fim: string,
    horariosOcupados: string[]
  ) => string[];
}

const AgendamentoContext = createContext<AgendamentoContextProps | undefined>(
  undefined
);

interface AgendamentoProviderProps {
  children: ReactNode;
}

export function AgendamentoProvider({ children }: AgendamentoProviderProps) {
  const [agendamentos, setAgendamentos] = useState<IAgendamento[]>([]);
  const [profissionais, setProfissionais] = useState<IProfissional[]>([]);
  const [unidades, setUnidades] = useState<IUnidade[]>([]);
  const [horarios, setHorarios] = useState<string[]>([]);
  const [servicos, setServicos] = useState<IServico[]>([]);
  const [clientes, setClientes] = useState<ICliente[]>([]);
  const [especialidades, setEspecialidades] = useState<IEspecialidade[]>([]);

  async function getAgendamentos() {
    try {
      const response = await axiosInstance.get('/agendamento/');
      const data = response.data.map((ag: any) => ({
        ...ag,
        data_hora: new Date(ag.data_hora),
      }));
      setAgendamentos(data);
    } catch (error) {
      console.error('Erro ao buscar agendamentos:', error);
    }
  }

  async function getProfissional() {
    try {
      const response = await axiosInstance.get('/profissional/');
      setProfissionais(response.data);
    } catch (error) {
      console.error('Erro ao buscar profissionais:', error);
    }
  }

  async function getUnidades() {
    try {
      const response = await axiosInstance.get('/unidade/');
      setUnidades(response.data);
    } catch (error) {
      console.error('Erro ao buscar unidades:', error);
    }
  }

  async function getServicos() {
    try {
      const response = await axiosInstance.get('/servico/');
      setServicos(response.data);
    } catch (error) {
      console.error('Erro ao buscar servicos:', error);
    }
  }

  async function getClientes() {
    try {
      const response = await axiosInstance.get('/cliente/');
      setClientes(response.data);
    } catch (error) {
      console.error('Erro ao buscar clientes:', error);
    }
  }

  async function getEspecialidades() {
    try {
      const response = await axiosInstance.get('/especialidade/');
      setEspecialidades(response.data);
    } catch (error) {
      console.error('Erro ao buscar especialidades:', error);
    }
  }

  function getHorarios() {
    const horariosOcupadosMock = ['09:00', '09:30', '11:00', '14:00'];
    setHorarios(
      gerarHorariosDisponiveis('08:00', '18:00', horariosOcupadosMock)
    );
  }

  async function createAgendamento(data: Partial<IAgendamento>) {
    try {
      await axiosInstance.post('/agendamento/', data);
      getAgendamentos();
    } catch (error) {
      console.error('Erro ao criar agendamento:', error);
      throw error;
    }
  }

  async function updateAgendamento(id: number, data: Partial<IAgendamento>) {
    try {
      await axiosInstance.put(`/agendamento/${id}`, data);
      getAgendamentos();
    } catch (error) {
      console.error('Erro ao atualizar agendamento:', error);
      throw error;
    }
  }

  async function deleteAgendamento(id: number) {
    try {
      await axiosInstance.delete(`/agendamento/${id}`);
      getAgendamentos();
    } catch (error) {
      console.error('Erro ao deletar agendamento:', error);
      throw error;
    }
  }

  function gerarHorariosDisponiveis(
    inicio: string,
    fim: string,
    horariosOcupados: string[]
  ): string[] {
    const horariosGerados: string[] = [];
    const [horaInicio, minutoInicio] = inicio.split(':').map(Number);
    const [horaFim, minutoFim] = fim.split(':').map(Number);

    let current = new Date();
    current.setHours(horaInicio, minutoInicio, 0, 0);

    const fimDia = new Date();
    fimDia.setHours(horaFim, minutoFim, 0, 0);

    while (isBefore(current, fimDia)) {
      const horarioFormatado = format(current, 'HH:mm');
      if (!horariosOcupados.includes(horarioFormatado)) {
        horariosGerados.push(horarioFormatado);
      }
      current = addMinutes(current, 30);
    }
    return horariosGerados;
  }

  useEffect(() => {
    getAgendamentos();
    getProfissional();
    getUnidades();
    getServicos();
    getClientes();
    getEspecialidades();
    getHorarios();
  }, []);

  return (
    <AgendamentoContext.Provider
      value={{
        agendamentos,
        profissionais,
        unidades,
        horarios,
        servicos,
        clientes,
        especialidades,

        getAgendamentos,
        getProfissional,
        getUnidades,
        getHorarios,
        getServicos,
        getClientes,
        getEspecialidades,

        createAgendamento,
        updateAgendamento,
        deleteAgendamento,

        gerarHorariosDisponiveis,
      }}
    >
      {children}
    </AgendamentoContext.Provider>
  );
}

export function useAgendamento() {
  const context = useContext(AgendamentoContext);
  if (!context) {
    throw new Error(
      'useAgendamento deve ser usado dentro de um AgendamentoProvider'
    );
  }
  return context;
}
