import React, { useState } from 'react';
import {
  Container,
  Header,
  FilterBar,
  SearchInput,
  AddButton,
  Table,
  StatusBadge,
  Pagination,
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContainer,
  Tab,
  Form,
  TextField,
  TextArea,
  UploadArea,
  ContainerFlex,
  HeaderImport,
} from './style';

import lapis from './assets/lapis.png';
import { toast } from 'react-toastify';

import { useCliente } from '../../context/ClienteContext/ClienteContext';
import ModalDelete from '../../hooks/ModalDelete/ModalDelete';

const Clientes = () => {
  const { clients, addClient, editClient, deleteClient } = useCliente();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('addClient');

  const [selectedId, setSelectedId] = useState<number | null>(null);

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [endereco, setEndereco] = useState('');
  const [informacoesAdicionais, setInformacoesAdicionais] = useState('');
  const [senha, setSenha] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setSelectedId(null);
    setNome('');
    setEmail('');
    setTelefone('');
    setEndereco('');
    setInformacoesAdicionais('');
    setSenha('');
  };

  const handleOpenModalAdd = () => {
    resetForm();
    openModal();
  };

  const handleOpenModalEdit = (client) => {
    setSelectedId(client.id || null);
    setNome(client.nome);
    setEmail(client.email);
    setTelefone(client.telefone);
    setEndereco(client.endereco || '');
    setInformacoesAdicionais(client.informacoesAdicionais || '');
    setSenha('');
    openModal();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (selectedId) {
        await editClient(selectedId, {
          nome,
          email,
          telefone,
          endereco,
          informacoesAdicionais,
          senha,
        });
        toast.success('Cliente editado com sucesso!');
      } else {
        await addClient({
          nome,
          email,
          telefone,
          endereco,
          informacoesAdicionais,
          senha,
        });
        toast.success('Cliente adicionado com sucesso!');
      }
      closeModal();
    } catch (error) {
      console.error('Erro ao salvar cliente:', error);
    }
  };

  const renderStatusBadge = (status?: string) => {
    switch (status) {
      case 'Em dia':
        return <StatusBadge color="green">● Em dia</StatusBadge>;
      case 'A fazer':
        return <StatusBadge color="orange">● A fazer</StatusBadge>;
      case 'Risco':
        return <StatusBadge color="red">● Risco</StatusBadge>;
      default:
        return null;
    }
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [message, setMessage] = useState('');

  const openDeleteModal = () => {
    setMessage(`Deseja excluir o cliente ${nome} ?`);

    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    handleDelete(selectedId);

    setIsDeleteModalOpen(false);
  };

  const handleDelete = async (clientId: number) => {
    try {
      await deleteClient(clientId);
      toast.success('Cliente excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir cliente:', error);
      toast.error('Erro ao excluir o cliente.');
    }
  };

  // Render
  return (
    <>
      <Container>
        <Header>
          <h1>Clientes</h1>
          <AddButton onClick={handleOpenModalAdd}>
            Adicionar cliente +
          </AddButton>
        </Header>

        <FilterBar>
          <select>
            <option value="">Tudo</option>
            <option value="Em dia">Em dia</option>
            <option value="A fazer">A fazer</option>
            <option value="Risco">Risco</option>
          </select>
          <SearchInput placeholder="Pesquisar" />
        </FilterBar>

        <Table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client, index) => (
              <tr key={index}>
                <td>{client.nome}</td>
                <td>{client.email}</td>
                <td>{client.telefone}</td>
                {/* {renderStatusBadge(client.status)} */}
                <td>
                  <button onClick={() => handleOpenModalEdit(client)}>
                    <img src={lapis} alt="Editar" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination>
          <span>1-2 de 2</span>
          <div>
            <span>Itens por página: 15</span>
            <button>{'<'}</button>
            <span>1 / 2</span>
            <button>{'>'}</button>
          </div>
        </Pagination>

        {isModalOpen && (
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>
                <TabContainer>
                  <Tab
                    active={activeTab === 'addClient'}
                    onClick={() => setActiveTab('addClient')}
                  >
                    {selectedId ? 'Editar Cliente' : 'Adicionar cliente'}
                  </Tab>
                </TabContainer>
                <CloseButton onClick={closeModal}>×</CloseButton>
              </ModalHeader>
              <ModalBody>
                {activeTab === 'addClient' && (
                  <Form onSubmit={handleSubmit}>
                    <ContainerFlex>
                      <div>
                        <label>Nome completo</label>
                        <TextField
                          type="text"
                          placeholder="Nome do cliente"
                          name="nome"
                          value={nome}
                          onChange={(e) => setNome(e.target.value)}
                        />

                        <label>Endereço</label>
                        <TextField
                          type="text"
                          placeholder="Endereço"
                          name="endereco"
                          value={endereco}
                          onChange={(e) => setEndereco(e.target.value)}
                        />

                        <label>Email</label>
                        <TextField
                          type="email"
                          placeholder="Email do cliente"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />

                        <label>Telefone</label>
                        <TextField
                          type="text"
                          placeholder="Telefone"
                          name="telefone"
                          value={telefone}
                          onChange={(e) => setTelefone(e.target.value)}
                        />

                        <label>Senha</label>
                        <TextField
                          type="password"
                          placeholder="Digite sua senha"
                          name="senha"
                          value={senha}
                          onChange={(e) => setSenha(e.target.value)}
                        />
                      </div>
                      <div>
                        <label>Informações adicionais</label>
                        <TextArea
                          placeholder="Adicione informações extras..."
                          name="informacoesAdicionais"
                          value={informacoesAdicionais}
                          onChange={(e) =>
                            setInformacoesAdicionais(e.target.value)
                          }
                        />
                      </div>
                    </ContainerFlex>
                    <br />
                    <ModalFooter>
                      {selectedId ? (
                        <>
                          <button type="submit" onClick={handleSubmit}>
                            Editar Cliente
                          </button>
                          {/* <button
                            style={{ marginLeft: '10px' }}
                            onClick={() => handleDelete(selectedId)}
                          >
                            Excluir
                          </button> */}
                        </>
                      ) : (
                        <button type="submit">Adicionar cliente</button>
                      )}

                      <button type="button" onClick={closeModal}>
                        Cancelar
                      </button>
                    </ModalFooter>
                  </Form>
                )}
              </ModalBody>
            </ModalContent>
          </ModalOverlay>
        )}
        <ModalDelete
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
          title="Excluir Cliente"
          message={message}
        />
      </Container>
    </>
  );
};

export default Clientes;
