import styled from 'styled-components';

interface ContainerListaLinkProps {
  isOpen: boolean;
}

export const ContainerNavbar = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  max-width: 1920px;

  margin: 0 auto;

  @media (max-width: 1130px) {
    padding: 20px;
    width: 10% !important;
    flex-direction: row;
    align-items: center;
  }
`;

export const ContainerListaLogo = styled.ul`
  list-style: none;
  margin-left: auto;

  @media (max-width: 1130px) {
    margin-left: 0;
  }
`;

export const ContainerListaLink = styled.ul<ContainerListaLinkProps>`
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: center;

  li {
    margin: 0 15px;
  }

  .selected {
    a {
      color: #7ef14a;

      &:hover {
        color: #000;
      }
    }
  }

  a {
    text-decoration: none;
    color: #000;
    font-weight: bold;

    &:hover {
      color: #7ef14a;
    }
  }

  button {
    background-color: #757575;
    color: white;
    border: none;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 12px;
    width: 160px;
    cursor: pointer;
    display: none;
  }

  @media (max-width: 1130px) {
    display: none;

    button {
      display: block;
    }
  }
`;

export const ContainerListaLogin = styled.ul`
  list-style: none;

  button {
    background-color: #757575;
    color: white;
    border: none;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 12px;
    width: 140px;
    cursor: pointer;

    transition-duration: 0.3s;

    &:hover {
      background-color: rgba(106, 17, 192, 1);
    }
  }

  @media (max-width: 1130px) {
    display: none;
  }
`;

export const HamburgerMenu = styled.div`
  padding: 20px;
  display: none;
  cursor: pointer;
  background-color: #5503a5;
  border-radius: 10px;
  margin-left: 90%;

  @media (max-width: 1130px) {
    display: block;
  }
`;

interface MenuIconProps {
  isOpen: boolean;
}

export const MenuIcon = styled.div<MenuIconProps>`
  width: 25px;
  height: 3px;
  background-color: ${({ isOpen }) => (isOpen ? 'transparent' : 'white')};
  position: relative;
  transition: all 0.3s linear;

  &::before,
  &::after {
    content: '';
    width: 25px;
    height: 3px;
    background-color: white;
    position: absolute;
    transition: all 0.3s linear;
  }

  &::before {
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(45deg)' : 'translateY(-10px)'};
  }

  &::after {
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(-45deg)' : 'translateY(10px)'};
  }
`;

export const MobileMenuContainer = styled.ul<ContainerListaLinkProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 100;

  li {
    margin: 20px 0;
    width: 80%;
    text-align: center;
  }

  a,
  button {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: block;
    text-align: center;
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #7ef14a;
    color: #7ef14a;
    font-weight: 600;
    margin: 10px 0;
  }

  .active {
    a {
      background-color: #5503a5;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      color: white;
      border: 1px solid #5503a5;
    }
  }

  button {
    background-color: #757575;
    color: white;
    border: none;
    font-weight: 600;
    padding: 10px 20px;
    width: 100%;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-size: 1.5rem;
  }
`;

export const CloseIcon = styled.div`
  align-self: flex-end;
  cursor: pointer;
  padding: 30px;

  &::before {
    content: '✕';
    font-size: 2rem;
    color: #5503a5;
  }
`;
