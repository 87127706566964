import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    font-size: 24px;
    color: #333;
  }
`;

export const FilterBar = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  select {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
  }

  nav {
    display: flex;
    gap: 10px;

    a {
      font-size: 14px;
      color: #5a5a5a;
      text-decoration: none;

      span {
        color: #6a1b9a;
        margin-left: 5px;
        padding: 5px;
        border-radius: 10px;
        background-color: #e9edf5;
      }
    }
  }
`;

export const SearchInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
`;

export const AddButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #5503a5;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th,
  td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }

  th {
    color: #333;
    font-weight: 600;
  }

  td {
    color: #5a5a5a;
  }

  button {
    background: none;
    border: none;
    color: #6a1b9a;
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }
`;

export const StatusBadge = styled.span<{ color: string }>`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 12px;
  color: ${({ color }) =>
    color === 'green' ? '#14804A' : color === 'orange' ? '#AA5B00' : '#D1293D'};
  background-color: ${({ color }) =>
    color === 'green' ? '#E1FCEF' : color === 'orange' ? '#FCF2E6' : '#FFEDEF'};
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #5a5a5a;
  margin-top: auto;

  div {
    display: flex;
    gap: 10px;

    span {
      display: flex;
      align-items: center;
      padding: 8px 12px;
    }

    button {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      background-color: #fff;

      &:hover {
        color: #333;
      }
    }
  }

  span {
    margin-top: auto;
  }
`;
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  width: 60%;
  max-width: 90%;
  height: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  animation: slideDown 0.3s ease;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;

  &:hover {
    color: #333;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  gap: 20px;
`;

interface TabProps {
  active?: boolean;
}

export const Tab = styled.button<TabProps>`
  border: none;
  background: none;
  font-size: 16px;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? '#6a1b9a' : '#666')};
  cursor: pointer;
  padding: 10px 0;
  border-bottom: ${({ active }) => (active ? '2px solid #6a1b9a' : 'none')};

  &:hover {
    color: #6a1b9a;
  }
`;

export const ModalBody = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Form = styled.div`
  display: flex;
  gap: 20px;

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  label {
    margin-top: 10px;
    font-weight: 600;
    color: #555;
  }
`;

export const TextField = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;

  &:focus {
    border-color: #6a1b9a;
  }
`;

export const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  height: 120px;
  margin-top: 5px;

  &:focus {
    border-color: #6a1b9a;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  border-top: 1px solid #ddd;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:first-child {
      background-color: #6a1b9a;
      color: #fff;

      &:hover {
        background-color: #ccc;
      }
    }

    &:last-child {
      background-color: #ddd;
      color: #333;
      margin-left: 10px;

      &:hover {
        color: white;
        background-color: #5a0e8e;
      }
    }
  }

  .button-delete {
    background-color: #ddd;
    color: #333;
    margin-left: 10px;

    &:hover {
      color: white;
      background-color: #5a0e8e;
    }
  }
`;

/*  */

export const WarningMessage = styled.div`
  background-color: #fff8e1;
  border-left: 4px solid #ffeb3b;
  padding: 10px;
  border-radius: 4px;
  color: #795548;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-weight: bold;
  }

  button {
    background: none;
    border: none;
    font-size: 16px;
    color: #795548;
    cursor: pointer;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  p {
    flex: 1;
    color: #666;
  }

  small {
    color: #999;
  }
`;

interface ProgressBarProps {
  progress: number;
}

export const ProgressBar = styled.div<ProgressBarProps>`
  width: 100%;
  height: 8px;
  background-color: #f3e5f5;
  border-radius: 4px;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: ${({ progress }) => progress}%;
    background-color: #6a1b9a;
    transition: width 0.3s ease;
  }
`;

export const UploadArea = styled.div`
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #666;
  margin-bottom: 20px;

  span {
    color: #6a1b9a;
    cursor: pointer;
  }

  small {
    display: block;
    margin-top: 5px;
    color: #999;
  }
`;

export const HeaderImport = styled.div`
  display: flex;
  justify-content: space-between;
`;
