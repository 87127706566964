import {
  CardContent,
  CardDescription,
  CardIcon,
  CardOportunityOne,
  CardOportunityThree,
  CardOportunityTwo,
  CardTitle,
  ContainerCardIcon,
  ContainerOportunity,
  ContainerOportunityCards,
  ContainerOportunityCardsSecond,
  ContainerOportunityText,
} from './style';
import ChatIco from '../assets/ico/chat_ico.svg';
import CalendarIco from '../assets/ico/calendar_ico.svg';
import PartnerLogo from '../assets/blip_partner.svg';
import DashIco from '../assets/ico/dash_ico.svg';
import PersonIco from '../assets/ico/person_ico.svg';
import RocketIco from '../assets/ico/rocket_ico.svg';

const Oportunity: React.FC = () => {
  return (
    <ContainerOportunity id="oportunity">
      <ContainerOportunityText>
        <h1>Oportunidades que oferecemos</h1>
        <p>
          A Haaify oferece diversos serviços pensados para facilitar a
          comunicação dentro e fora do seu negócio.
        </p>
      </ContainerOportunityText>
      <ContainerOportunityCards>
        <CardOportunityOne>
          <ContainerCardIcon>
            <CardIcon>
              <img src={ChatIco} alt={`${ChatIco} icon`} />
            </CardIcon>
            <CardIcon>
              <img
                src={PartnerLogo}
                className="partner-ico"
                alt={`${PartnerLogo} icon`}
              />
            </CardIcon>
          </ContainerCardIcon>

          <CardContent>
            <CardTitle style={{ marginTop: "55px" }}>Automação de atendimento</CardTitle>
            <CardDescription>
              A automação de atendimento revoluciona a conexão empresa-cliente,
              oferecendo respostas rápidas e personalizadas 24/7, enquanto
              libera recursos internos para tarefas mais estratégicas.
            </CardDescription>
          </CardContent>
        </CardOportunityOne>
        <CardOportunityTwo>
          <CardIcon>
            <img src={CalendarIco} alt={`${CalendarIco} icon`} />
          </CardIcon>
          <CardContent>
            <CardTitle>Canal de agendamentos</CardTitle>
            <CardDescription>
              O canal de agendamentos automatizado simplifica o processo de
              marcação de compromissos, proporcionando conveniência e eficiência
              tanto para clientes quanto para a empresa.
            </CardDescription>
          </CardContent>
        </CardOportunityTwo>
      </ContainerOportunityCards>
      <ContainerOportunityCardsSecond>
        <CardOportunityThree>
          <CardIcon>
            <img src={DashIco} alt={`${DashIco} icon`} />
          </CardIcon>
          <CardContent>
            <CardTitle>Gestão de métricas</CardTitle>
            <CardDescription>
              Receba insights valiosos para tomadas de decisão estratégicas.
            </CardDescription>
          </CardContent>
        </CardOportunityThree>
        <CardOportunityThree>
          <CardIcon>
            <img src={PersonIco} alt={`${PersonIco} icon`} />
          </CardIcon>
          <CardContent>
            <CardTitle>Inteligência artificial</CardTitle>
            <CardDescription>
              Automatize tarefas complexas, impulsionando a eficiência e a
              inovação na sua empresa.
            </CardDescription>
          </CardContent>
        </CardOportunityThree>
        <CardOportunityThree>
          <CardIcon>
            <img src={RocketIco} alt={`${RocketIco} icon`} />
          </CardIcon>
          <CardContent>
            <CardTitle>Consultorias</CardTitle>
            <CardDescription>
              Te auxiliamos a superar desafios e alcançar seus objetivos.
            </CardDescription>
          </CardContent>
        </CardOportunityThree>
      </ContainerOportunityCardsSecond>
    </ContainerOportunity>
  );
};

export default Oportunity;
