import styled from 'styled-components';

export const ContainerModalDelete = styled.div``;

export const TabContainer = styled.div`
  display: flex;
  gap: 20px;
`;

interface TabProps {
  active: boolean;
}

export const Tab = styled.button<TabProps>`
  border: none;
  background: none;
  font-size: 16px;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? '#6a1b9a' : '#666')};
  cursor: pointer;
  padding: 10px 0;
  border-bottom: ${({ active }) => (active ? '2px solid #6a1b9a' : 'none')};

  &:hover {
    color: #6a1b9a;
  }
`;

export const ModalBody = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Form = styled.div`
  display: block;
  gap: 20px;

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  label {
    margin-top: 10px;
    font-weight: 600;
    color: #555;
  }

  select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 5px;

    &:focus {
      border-color: #6a1b9a;
    }
  }
`;

export const ContainerFlex = styled.main`
  display: flex;
  justify-content: space-around;

  /*   div {
    width: 45%;
  } */
`;

export const TextField = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;

  &:focus {
    border-color: #6a1b9a;
  }
`;

export const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  height: 120px;
  margin-top: 5px;

  &:focus {
    border-color: #6a1b9a;
  }
`;

export const ModalFooter = styled.main`
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  border-top: 1px solid #ddd;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:first-child {
      color: #6a1b9a;
      border: 1px solid #6a1b9a;
      background-color: transparent;

      &:hover {
        color: white;
        border: 1px solid #6a1b9a;
        background-color: #6a1b9a;
      }
    }

    &:last-child {
      background-color: #dc2626;
      color: white;
      margin-left: 10px;
      border: 1px solid #dc2626;

      &:hover {
        color: white;
        background-color: rgba(100, 38, 38, 1);
        border: 1px solid #dc2626;
      }
    }
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  width: 30%;
  max-width: 90%;
  height: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  animation: slideDown 0.3s ease;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;

  &:hover {
    color: #333;
  }
`;
