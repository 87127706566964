import { createContext, ReactNode, useContext, useState } from 'react';
import { IEspecialidade } from '../../interfaces/Especialidade/Especialidade';

interface EspecialidadeContextType {
  especialidades: IEspecialidade[];
  setEspecialidades: React.Dispatch<React.SetStateAction<IEspecialidade[]>>;
}

const EspecialidadeContext = createContext<
  EspecialidadeContextType | undefined
>(undefined);

interface EspecialidadeProviderProps {
  children: ReactNode;
}

export const EspecialidadeProvider = ({
  children,
}: EspecialidadeProviderProps) => {
  const [especialidades, setEspecialidades] = useState<IEspecialidade[]>([]);

  return (
    <EspecialidadeContext.Provider
      value={{ especialidades, setEspecialidades }}
    >
      {children}
    </EspecialidadeContext.Provider>
  );
};

export function useEspecialidade() {
  const context = useContext(EspecialidadeContext);
  if (!context) {
    throw new Error(
      'useEspecialidade must be used within an EspecialidadeProvider'
    );
  }
  return context;
}
