import React, { useEffect, useState } from 'react';
import {
  Sidebar,
  Logo,
  Menu,
  MenuItem,
  StyledNavLink,
  SubMenu,
  SubMenuItem,
  DropdownToggle,
  DropdownIcon,
  BottomMenu,
  ToggleButton,
  FloatingChatInput,
  FloatingChatContainer,
  FloatingResponse,
  LoadingMessage,
  RobocopDiv,
} from './style';
import logoImage from '../../assets/logo.svg';
import contact from './assets/Contacts.svg';
import calendar from './assets/Calendar.svg';
import user from './assets/Users.svg';
import trending from './assets/Trending_up.svg';
import setinha from './assets/Seta.svg';
import settings from './assets/Settings.svg';
import headphones from './assets/Headphones.svg';
import logout from './assets/Log_out.svg';
import robocop from './assets/TED-removebg-preview.png';

import message from './assets/Message.svg';

import home from './assets/Home.svg';

import miniLogo from '../../../../assets/logo_fadein.png';
import axios from 'axios';
import { useSidebar } from '../../../../context/SidebarContext/SidebarContext';

const Navbar: React.FC = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const { isMinimized, toggleSidebar } = useSidebar();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  /* Haaify AI */

  /* const [isChatVisible, setIsChatVisible] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [aiResponse, setAiResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSendMessage = async () => {
    if (userMessage.trim()) {
      setAiResponse('');
      setLoading(true);

      try {
        const response = await axios.get(
          `https://langchainhaaify-app-4c2dj.ondigitalocean.app/api/generate-answer?question=${userMessage}`
        );
        setAiResponse(response.data.response);
      } catch (error) {
        setAiResponse('Desculpe, TED está descansando.');
      } finally {
        setLoading(false);
      }
    }
  }; */
  if (isMobile) {
    return <>Em construção a navbar 👀👀👀</>;
  } else {
    return (
      <Sidebar
        isMinimized={isMinimized}
        onMouseEnter={() => isMinimized && toggleSidebar()}
        onMouseLeave={() => !isMinimized && toggleSidebar()}
      >
        <Logo>
          {!isMinimized ? (
            <img src={logoImage} alt="Haaify Logo" />
          ) : (
            <img src={miniLogo} alt="Haaify Logo" />
          )}
        </Logo>
        {/* <ToggleButton onClick={toggleSidebar}>
          {isMinimized ? '>' : ''}
        </ToggleButton> */}

        <br />
        <br />

        <Menu>
          {/* <MenuItem>
            <StyledNavLink to="/d/agenda">
              <img src={contact} alt="" />
              {!isMinimized && 'Minha agenda'}
            </StyledNavLink>
          </MenuItem>
          <MenuItem>
            <StyledNavLink to="/d/calendario">
              <img src={calendar} alt="" />
              {!isMinimized && 'Calendário'}
            </StyledNavLink>
          </MenuItem> */}

          <MenuItem>
            <StyledNavLink to="/d/home">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.64 5.7697L9.48375 0.144714C9.33972 0.0512591 9.1717 0.00152588 9 0.00152588C8.8283 0.00152588 8.66028 0.0512591 8.51625 0.144714L0.360022 5.7697C0.24799 5.84809 0.156688 5.95253 0.0939761 6.07404C0.0312641 6.19554 -0.000979023 6.33047 2.26448e-05 6.4672V14.9047C2.26448e-05 15.7252 0.32597 16.5121 0.906159 17.0923C1.48635 17.6725 2.27325 17.9984 3.09376 17.9984H5.62501V13.2172C5.62501 12.3967 5.95096 11.6098 6.53114 11.0296C7.11133 10.4494 7.89824 10.1234 8.71875 10.1234H9.28125C10.1018 10.1234 10.8887 10.4494 11.4689 11.0296C12.049 11.6098 12.375 12.3967 12.375 13.2172V17.9984H14.9062C15.7267 17.9984 16.5137 17.6725 17.0938 17.0923C17.674 16.5121 18 15.7252 18 14.9047V6.4672C18.001 6.33047 17.9687 6.19554 17.906 6.07404C17.8433 5.95253 17.752 5.84809 17.64 5.7697Z"
                  fill="white"
                />
              </svg>

              {!isMinimized && 'Inicio'}
            </StyledNavLink>
          </MenuItem>

          <MenuItem>
            <StyledNavLink to="/d/gestao">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.52051 0.5C3.46465 0.502804 2.45284 0.923486 1.70623 1.67009C0.959619 2.4167 0.538937 3.42852 0.536133 4.48438L0.536133 13.5156C0.538937 14.5715 0.959619 15.5833 1.70623 16.3299C2.45284 17.0765 3.46465 17.4972 4.52051 17.5H13.2861V0.5H4.52051Z"
                  fill="white"
                />
                <path
                  d="M9.47194 8.3731C9.86772 7.97495 10.0899 7.43637 10.0899 6.87497C10.0899 6.31358 9.86772 5.77499 9.47194 5.37685C9.0738 4.98106 8.53521 4.75891 7.97382 4.75891C7.41242 4.75891 6.87384 4.98106 6.47569 5.37685C6.25324 5.56735 6.07257 5.80177 5.94502 6.06541C5.81747 6.32905 5.7458 6.6162 5.73449 6.90886C5.72319 7.20151 5.7725 7.49334 5.87934 7.76603C5.98617 8.03872 6.14822 8.28639 6.35531 8.49348C6.5624 8.70057 6.81006 8.86262 7.08275 8.96945C7.35545 9.07628 7.64728 9.1256 7.93993 9.11429C8.23258 9.10299 8.51974 9.03131 8.78338 8.90376C9.04701 8.77621 9.28144 8.59554 9.47194 8.3731ZM7.44257 6.87497C7.44133 6.78708 7.46193 6.70025 7.5025 6.62228C7.54308 6.5443 7.60237 6.47761 7.67506 6.42819C7.74775 6.37876 7.83157 6.34815 7.91901 6.33909C8.00644 6.33003 8.09476 6.3428 8.17604 6.37627C8.25732 6.40974 8.32902 6.46286 8.38473 6.53086C8.44043 6.59885 8.47839 6.67961 8.4952 6.76589C8.51202 6.85217 8.50716 6.94127 8.48107 7.02521C8.45497 7.10915 8.40846 7.18531 8.34569 7.24685C8.24638 7.34418 8.11287 7.39871 7.97382 7.39871C7.83476 7.39871 7.70125 7.34418 7.60194 7.24685C7.5509 7.19941 7.51035 7.14182 7.48291 7.07777C7.45546 7.01373 7.44172 6.94465 7.44257 6.87497Z"
                  fill="#5503A5"
                />
                <path
                  d="M4.86052 12.1237C4.81451 12.219 4.78818 12.3225 4.78313 12.4282C4.77807 12.5338 4.79438 12.6394 4.83108 12.7386C4.86779 12.8378 4.92413 12.9286 4.99674 13.0055C5.06934 13.0824 5.15672 13.1439 5.25365 13.1862C5.34913 13.2308 5.4525 13.2559 5.55777 13.2602C5.66304 13.2645 5.76811 13.2479 5.86692 13.2114C5.96573 13.1748 6.05631 13.119 6.13342 13.0472C6.21053 12.9754 6.27264 12.8891 6.31615 12.7931C6.4664 12.4734 6.69919 12.1995 6.99054 11.9996C7.28188 11.7998 7.62124 11.6813 7.97365 11.6563C8.32679 11.6784 8.66761 11.7945 8.96087 11.9925C9.25413 12.1905 9.4892 12.4632 9.64177 12.7825C9.70259 12.923 9.80366 13.0424 9.93224 13.1256C10.0608 13.2088 10.2111 13.2521 10.3643 13.25C10.4783 13.2508 10.591 13.2253 10.6936 13.1756C10.7906 13.1333 10.8779 13.0718 10.9506 12.9949C11.0232 12.918 11.0795 12.8272 11.1162 12.728C11.1529 12.6288 11.1692 12.5232 11.1642 12.4176C11.1591 12.3119 11.1328 12.2084 11.0868 12.1131C10.8081 11.5169 10.3697 11.0097 9.82013 10.6477C9.27057 10.2857 8.6314 10.0831 7.97365 10.0625C7.31475 10.0841 6.6748 10.2882 6.12512 10.6522C5.57544 11.0161 5.13764 11.5256 4.86052 12.1237Z"
                  fill="#5503A5"
                />
                <path
                  d="M16.9215 0.964483C16.7486 0.816228 16.5432 0.698839 16.317 0.619113C16.0909 0.539387 15.8485 0.498909 15.604 0.500022H14.8071V1.85698V3.23215C14.8071 3.35291 14.8631 3.46873 14.9627 3.55413C15.0624 3.63953 15.1975 3.6875 15.3384 3.6875H17.4634V2.09376C17.4647 1.88417 17.4175 1.67643 17.3244 1.48259C17.2314 1.28875 17.0945 1.11264 16.9215 0.964483Z"
                  fill="white"
                />
                <path
                  d="M16.9215 17.0345C16.7486 17.1827 16.5432 17.3001 16.317 17.3798C16.0909 17.4596 15.8485 17.5001 15.604 17.4989H14.8071V16.142V14.7668C14.8071 14.646 14.8631 14.5302 14.9627 14.4448C15.0624 14.3594 15.1975 14.3115 15.3384 14.3115H17.4634V15.9052C17.4647 16.1148 17.4175 16.3225 17.3244 16.5164C17.2314 16.7102 17.0945 16.8863 16.9215 17.0345Z"
                  fill="white"
                />
                <path
                  d="M16.9321 5.10382H15.3384C15.045 5.10382 14.8071 5.30769 14.8071 5.55918V7.83596C14.8071 8.08745 15.045 8.29132 15.3384 8.29132H16.9321C17.2255 8.29132 17.4634 8.08745 17.4634 7.83596V5.55918C17.4634 5.30769 17.2255 5.10382 16.9321 5.10382Z"
                  fill="white"
                />
                <path
                  d="M16.9321 9.70764H15.3384C15.045 9.70764 14.8071 9.91151 14.8071 10.163V12.4398C14.8071 12.6913 15.045 12.8951 15.3384 12.8951H16.9321C17.2255 12.8951 17.4634 12.6913 17.4634 12.4398V10.163C17.4634 9.91151 17.2255 9.70764 16.9321 9.70764Z"
                  fill="white"
                />
              </svg>

              {!isMinimized && 'Gestão de equipe'}
            </StyledNavLink>
          </MenuItem>

          <MenuItem>
            <DropdownToggle onClick={toggleSubMenu}>
              <img src={calendar} alt="" />
              {!isMinimized && 'Agendamentos'}
              {!isMinimized && (
                <DropdownIcon src={setinha} alt="" isOpen={isSubMenuOpen} />
              )}
            </DropdownToggle>
            {isSubMenuOpen && !isMinimized && (
              <SubMenu>
                {/* <SubMenuItem>
                  <StyledNavLink to="/d/clientes/novo">
                    Novo cliente
                  </StyledNavLink>
                </SubMenuItem> */}
                <SubMenuItem>
                  <StyledNavLink to="/d/clientes">Clientes</StyledNavLink>
                </SubMenuItem>
              </SubMenu>
            )}
          </MenuItem>

          <MenuItem>
            <StyledNavLink to="/d/enviomsgs">
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99994 16.739C14.5137 16.739 19.35 14.4821 19.35 8.6786C19.35 2.87513 14.5137 0.618225 9.99994 0.618225C5.48613 0.618225 0.649902 2.87513 0.649902 8.6786C0.649902 10.0067 0.903179 11.1491 1.34791 12.1211C1.62512 12.7271 1.78061 13.3953 1.65782 14.0502L1.31967 15.8536C1.15253 16.745 1.93346 17.5261 2.82493 17.3589L6.96134 16.5833C7.28649 16.5223 7.61987 16.5276 7.94654 16.5794C8.62645 16.6873 9.31693 16.739 9.99994 16.739Z"
                  fill="#FCFCFC"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.84131 8.67858C4.84131 7.9664 5.41868 7.38892 6.13097 7.38892C6.84326 7.38892 7.42063 7.9664 7.42063 8.67858C7.42063 9.39075 6.84326 9.96824 6.13097 9.96824C5.41868 9.96824 4.84131 9.39075 4.84131 8.67858Z"
                  fill="#5503A5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.71045 8.67858C8.71045 7.96637 9.2879 7.38892 10.0001 7.38892C10.7123 7.38892 11.2898 7.96637 11.2898 8.67858C11.2898 9.39078 10.7123 9.96824 10.0001 9.96824C9.2879 9.96824 8.71045 9.39078 8.71045 8.67858Z"
                  fill="#5503A5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.5796 8.67858C12.5796 7.96637 13.157 7.38892 13.8692 7.38892C14.5815 7.38892 15.1589 7.96637 15.1589 8.67858C15.1589 9.39078 14.5815 9.96824 13.8692 9.96824C13.157 9.96824 12.5796 9.39078 12.5796 8.67858Z"
                  fill="#5503A5"
                />
              </svg>

              {!isMinimized && 'Disparo em massa'}
            </StyledNavLink>
          </MenuItem>

          <MenuItem>
            <StyledNavLink to="/d/dash">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.6409 0.5H15.1096C14.0827 0.5 13.2502 1.33247 13.2502 2.35938V15.6406C13.2502 16.6675 14.0827 17.5 15.1096 17.5H15.6409C16.6678 17.5 17.5002 16.6675 17.5002 15.6406V2.35938C17.5002 1.33247 16.6678 0.5 15.6409 0.5Z"
                  fill="white"
                />
                <path
                  d="M9.26587 7.9375H8.73462C7.70771 7.9375 6.87524 8.76997 6.87524 9.79688V15.6406C6.87524 16.6675 7.70771 17.5 8.73462 17.5H9.26587C10.2928 17.5 11.1252 16.6675 11.1252 15.6406V9.79688C11.1252 8.76997 10.2928 7.9375 9.26587 7.9375Z"
                  fill="white"
                />
                <path
                  d="M2.89087 11.125H2.35962C1.33271 11.125 0.500244 11.9575 0.500244 12.9844V15.6406C0.500244 16.6675 1.33271 17.5 2.35962 17.5H2.89087C3.91777 17.5 4.75024 16.6675 4.75024 15.6406V12.9844C4.75024 11.9575 3.91777 11.125 2.89087 11.125Z"
                  fill="white"
                />
                <path
                  d="M8.20291 0.5H4.48416C4.27367 0.502752 4.07258 0.587592 3.92373 0.736442C3.77488 0.885293 3.69004 1.08639 3.68729 1.29688C3.69004 1.50736 3.77488 1.70846 3.92373 1.85731C4.07258 2.00616 4.27367 2.091 4.48416 2.09375H6.27979L0.733537 7.64C0.584308 7.78941 0.500488 7.99195 0.500488 8.20312C0.500488 8.4143 0.584308 8.61684 0.733537 8.76625C0.882952 8.91548 1.08549 8.9993 1.29666 8.9993C1.50783 8.9993 1.71037 8.91548 1.85979 8.76625L7.40604 3.22V5.01562C7.40604 5.22697 7.48999 5.42966 7.63944 5.5791C7.78888 5.72854 7.99157 5.8125 8.20291 5.8125C8.41426 5.8125 8.61694 5.72854 8.76639 5.5791C8.91583 5.42966 8.99979 5.22697 8.99979 5.01562V1.29688C8.99704 1.08639 8.9122 0.885293 8.76335 0.736442C8.61449 0.587592 8.4134 0.502752 8.20291 0.5Z"
                  fill="white"
                />
              </svg>

              {!isMinimized && 'Dashboard'}
            </StyledNavLink>
          </MenuItem>

          <MenuItem>
            <StyledNavLink to="/d/eventos">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.64 5.7697L9.48375 0.144714C9.33972 0.0512591 9.1717 0.00152588 9 0.00152588C8.8283 0.00152588 8.66028 0.0512591 8.51625 0.144714L0.360022 5.7697C0.24799 5.84809 0.156688 5.95253 0.0939761 6.07404C0.0312641 6.19554 -0.000979023 6.33047 2.26448e-05 6.4672V14.9047C2.26448e-05 15.7252 0.32597 16.5121 0.906159 17.0923C1.48635 17.6725 2.27325 17.9984 3.09376 17.9984H5.62501V13.2172C5.62501 12.3967 5.95096 11.6098 6.53114 11.0296C7.11133 10.4494 7.89824 10.1234 8.71875 10.1234H9.28125C10.1018 10.1234 10.8887 10.4494 11.4689 11.0296C12.049 11.6098 12.375 12.3967 12.375 13.2172V17.9984H14.9062C15.7267 17.9984 16.5137 17.6725 17.0938 17.0923C17.674 16.5121 18 15.7252 18 14.9047V6.4672C18.001 6.33047 17.9687 6.19554 17.906 6.07404C17.8433 5.95253 17.752 5.84809 17.64 5.7697Z"
                  fill="white"
                />
              </svg>
              {!isMinimized && 'Eventos'}
            </StyledNavLink>
          </MenuItem>

          {/* <MenuItem>
            <StyledNavLink to="/d/clientes">
              <img src={user} alt="" />
              {!isMinimized && 'Clientes'}
            </StyledNavLink>
          </MenuItem> */}

          {/* <MenuItem>
            <StyledNavLink to="/d/calendario">
              <img src={calendar} alt="" />
              {!isMinimized && 'Calendario'}
            </StyledNavLink>
          </MenuItem> */}
        </Menu>

        <BottomMenu>
          <MenuItem>
            <StyledNavLink to="/d/config">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.08145 12.6778L2.44796 12.8919C3.45672 13.0498 4.17713 13.9737 4.10283 15.0146L4.00216 16.4254C3.97283 16.8365 4.19179 17.2234 4.55443 17.4024L5.56514 17.9003C5.92778 18.0793 6.36081 18.0123 6.65702 17.7334L7.67259 16.7735C8.42139 16.0656 9.57674 16.0656 10.3264 16.7735L11.342 17.7334C11.6382 18.0134 12.0703 18.0793 12.4339 17.9003L13.4465 17.4014C13.8082 17.2234 14.0262 16.8375 13.9969 16.4275L13.8961 15.0146C13.8219 13.9737 14.5423 13.0498 15.551 12.8919L16.9175 12.6778C17.3154 12.6159 17.636 12.3109 17.7259 11.9089L17.9752 10.7911C18.0652 10.3891 17.9058 9.97213 17.5735 9.74011L16.4328 8.94116C15.5911 8.35126 15.334 7.19938 15.8424 6.29443L16.5314 5.06854C16.7318 4.71157 16.6987 4.26461 16.4474 3.94265L15.7485 3.04573C15.4973 2.72375 15.0789 2.59077 14.6938 2.71176L13.3723 3.12572C12.3958 3.43168 11.3548 2.91873 10.9794 1.94783L10.473 0.634944C10.3244 0.250985 9.96182 -0.000990179 9.55812 2.92496e-06L8.4379 0.003007C8.03421 0.0040001 7.67259 0.257986 7.52598 0.642951L7.03229 1.94083C6.66087 2.91774 5.61499 3.43469 4.63556 3.12672L3.25928 2.69476C2.87317 2.57277 2.45285 2.70676 2.20165 3.03073L1.50763 3.92865C1.25642 4.25361 1.22612 4.70158 1.43041 5.05854L2.13517 6.28743C2.65422 7.1933 2.40007 8.35622 1.55357 8.94923L0.426535 9.73912C0.0941933 9.97213 -0.0651251 10.3891 0.0248005 10.7901L0.274057 11.9079C0.363002 12.3109 0.683613 12.6159 1.08145 12.6778Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.00038 6.93422C7.85956 6.93422 6.93471 7.85908 6.93471 8.99989C6.93471 10.1407 7.85956 11.0656 9.00038 11.0656C10.1412 11.0656 11.066 10.1407 11.066 8.99989C11.066 7.85908 10.1412 6.93422 9.00038 6.93422ZM5.69531 8.99989C5.69531 7.17457 7.17506 5.69482 9.00038 5.69482C10.8257 5.69482 12.3054 7.17457 12.3054 8.99989C12.3054 10.8252 10.8257 12.305 9.00038 12.305C7.17506 12.305 5.69531 10.8252 5.69531 8.99989Z"
                  fill="#5503A5"
                />
              </svg>

              {!isMinimized && 'Configurações'}
            </StyledNavLink>
          </MenuItem>
          <MenuItem>
            <StyledNavLink to="/d/home">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.5 9.13281C0.5 4.4384 4.30558 0.632812 9 0.632812C13.6945 0.632812 17.5 4.4384 17.5 9.13281V13.1172C17.5 13.5573 17.1432 13.9141 16.7031 13.9141C16.263 13.9141 15.9062 13.5573 15.9062 13.1172V9.13281C15.9062 5.3186 12.8143 2.22656 9 2.22656C5.18579 2.22656 2.09375 5.3186 2.09375 9.13281V13.1172C2.09375 13.5573 1.73698 13.9141 1.29688 13.9141C0.856773 13.9141 0.5 13.5573 0.5 13.1172V9.13281Z"
                  fill="white"
                />
                <path
                  d="M1.29688 12.5328C1.29688 10.9484 2.58126 9.66406 4.16562 9.66406C5.22187 9.66406 6.07812 10.5203 6.07812 11.5766V14.6578C6.07812 15.714 5.22187 16.5703 4.16562 16.5703C2.58126 16.5703 1.29688 15.286 1.29688 13.7016V12.5328Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.16563 10.4609C3.02135 10.4609 2.09375 11.3885 2.09375 12.5328V13.7016C2.09375 14.8459 3.02135 15.7734 4.16563 15.7734C4.78176 15.7734 5.28125 15.2739 5.28125 14.6578V11.5766C5.28125 10.9604 4.78176 10.4609 4.16563 10.4609ZM0.5 12.5328C0.5 10.5083 2.14116 8.86719 4.16563 8.86719C5.66197 8.86719 6.875 10.0802 6.875 11.5766V14.6578C6.875 16.1541 5.66197 17.3672 4.16563 17.3672C2.14116 17.3672 0.5 15.7261 0.5 13.7016V12.5328Z"
                  fill="white"
                />
                <path
                  d="M11.9219 11.5766C11.9219 10.5203 12.7781 9.66406 13.8344 9.66406C15.4188 9.66406 16.7031 10.9484 16.7031 12.5328V13.7016C16.7031 15.286 15.4188 16.5703 13.8344 16.5703C12.7781 16.5703 11.9219 15.714 11.9219 14.6578V11.5766Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.8344 10.4609C13.2182 10.4609 12.7188 10.9604 12.7188 11.5766V14.6578C12.7188 15.2739 13.2182 15.7734 13.8344 15.7734C14.9787 15.7734 15.9063 14.8459 15.9063 13.7016V12.5328C15.9063 11.3885 14.9787 10.4609 13.8344 10.4609ZM11.125 11.5766C11.125 10.0802 12.338 8.86719 13.8344 8.86719C15.8589 8.86719 17.5 10.5083 17.5 12.5328V13.7016C17.5 15.7261 15.8589 17.3672 13.8344 17.3672C12.338 17.3672 11.125 16.1541 11.125 14.6578V11.5766Z"
                  fill="white"
                />
              </svg>

              {!isMinimized && 'Ajuda e Suporte'}
            </StyledNavLink>
          </MenuItem>
          <MenuItem>
            <StyledNavLink to="/d/sair">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.2754 7.42749L22.1995 11.9999L17.2754 16.5723"
                  stroke="#FCFCFC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.8486 12H10.2417"
                  stroke="#FCFCFC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5727 1.7998H4.61408C3.06007 1.7998 1.80029 3.05958 1.80029 4.61359V19.386C1.80029 20.94 3.06007 22.1997 4.61408 22.1997H16.5727"
                  stroke="#FCFCFC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              {!isMinimized && 'Sair'}
            </StyledNavLink>
          </MenuItem>
        </BottomMenu>
        {/*       {isChatVisible && <></>}
        <FloatingChatContainer>
          <RobocopDiv>
            <img src={robocop} alt="robocop" />
          </RobocopDiv>
          <FloatingChatInput
            type="text"
            placeholder="Pergunte ao TED!"
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSendMessage();
            }}
          />
          {loading && <LoadingMessage />}{' '}
          {!loading && aiResponse && (
            <FloatingResponse>{aiResponse}</FloatingResponse>
          )}
        </FloatingChatContainer> */}
      </Sidebar>
    );
  }
};

export default Navbar;
