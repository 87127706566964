import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../middleware/ProtectedRoute';
import Barbearia from '../pages/Barbearia';
import RobotTed from '../pages/RobotTed';
import Sair from '../utils/sair';
import AccountDetails from '../pages/AccountDetails';
import Clientes from '../pages/Clientes';
import GestaoEquipe from '../pages/GestaoEquipe/GestaoEquipe';
import Calendario from '../pages/Calendario';
import Eventos from '../pages/Eventos/Eventos';
import Login from '../pages/Login/Login';
import NotFound from '../pages/NotFound/NotFound';
import Home from '../pages/Home/Home';
import Dashboard from '../pages/Dashboard/Dashboard';
import Forgot from '../pages/Forgot/Forgot';
import Mensagens from '../pages/Mensagens/Mensagens';
import ProtectedLayout from '../components/ProtectedLayout/ProtectedLayout';
import { AgendamentoProvider } from '../context/AgendamentoContext/AgendamentoContext';

function RouterConfig() {
  return (
    <Routes>
      <Route path="*" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<Forgot />} />

      <Route path="/d" element={<ProtectedRoute />}>
        <Route element={<ProtectedLayout />}>
          <Route path="dash" element={<Dashboard />} />
          <Route path="enviomsgs" element={<Mensagens />} />
          <Route path="home" element={<RobotTed />} />
          <Route path="config" element={<AccountDetails />} />
          <Route path="clientes" element={<Clientes />} />

          <Route
            path="eventos"
            element={
              <AgendamentoProvider>
                <Eventos />
              </AgendamentoProvider>
            }
          />

          <Route path="gestao" element={<GestaoEquipe />} />
          <Route path="calendario" element={<Calendario />} />
          <Route path="sair" element={<Sair />} />
        </Route>
      </Route>

      <Route path="/barbearia" element={<Barbearia />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default RouterConfig;
