import React from 'react';
import { ContainerFooter, Links, SocialMedia } from './style';
import FacebookIco from '../../assets/icon/facebook_ico.svg';
import LinkedinIco from '../../assets/icon/linkedin_ico.svg';
import InstagramIco from '../../assets/icon/instagram_ico.svg';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <ContainerFooter>
      <Links>
        <ul>
          <li>
            <Link to={'/'}>Sobre nós</Link>
          </li>
          <li>
            <Link to={'/'}>Ferramentas</Link>
          </li>
          <li>
            <Link to={'/'}>Preços</Link>
          </li>
          <li>
            <Link to={'/'}>Contato</Link>
          </li>
        </ul>
      </Links>
      <SocialMedia>
        <ul>
          <li>
            <Link target="_blank" to={'https://www.facebook.com/share/12DP5yp5LMS/'}>
              <img title="Facebook" src={FacebookIco} />
            </Link>
          </li>
          <li>
            <Link
              target="_blank"
              to={'https://www.linkedin.com/company/haaify/'}
            >
              <img title="Linkedin" src={LinkedinIco} />
            </Link>
          </li>
          <li>
            <Link target="_blank" to={'https://www.instagram.com'}>
              <img title="Instagram" src={InstagramIco} />
            </Link>
          </li>
        </ul>
      </SocialMedia>
    </ContainerFooter>
  );
};

export default Footer;
