import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Sidebar = styled.div<{ isMinimized: boolean }>`
  height: 100vh;
  width: ${({ isMinimized }) => (isMinimized ? '80px' : '220px')};
  background-color: #5503a5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  transition: width 0.3s;

  div {
    img {
      justify-content: ${({ isMinimized }) => (isMinimized ? 'end' : 'end')};
    }
  }

  ul {
    padding-left: ${({ isMinimized }) => (isMinimized ? '13px' : '30px')};
  }
`;

export const Logo = styled.div`
  align-items: start;
  justify-content: start;
  width: 80%;
  /* margin-bottom: 20px; */

  text-align: center;
  img {
    height: 40px;
  }
`;

export const Menu = styled.ul`
  list-style: none;
  padding: 0;
  padding-left: 30px;
  width: 100%;
  flex: 1; /* This allows the menu to grow and push BottomMenu to the bottom */
`;

export const BottomMenu = styled.ul`
  width: 100%;
  list-style: none;
  margin-top: auto; /* Pushes this section to the bottom */
`;

export const MenuItem = styled.li`
  width: 93%;
  margin-bottom: 10px;
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px 0px 0px 5px;
  font-size: 16px;
  font-weight: bold;

  &.active {
    svg path {
      fill: #5503a5;
    }

    svg defs image {
      fill: #5503a5;
    }

    svg g path,
    svg g,
    svg {
      fill: #5503a5;
    }

    color: #5503a5;
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #d4d3d3;
  }

  img,
  svg {
    margin-right: 10px;
  }
`;

export const DropdownToggle = styled.div`
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px 0px 0px 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #d4d3d3;
  }

  img {
    margin-right: 10px;
  }
`;

export const DropdownIcon = styled.img<{ isOpen: boolean }>`
  margin-left: 10px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;

export const SubMenu = styled.ul`
  list-style: none;
  padding: 0 0 0 20px;
  margin: 5px 0;
`;

export const SubMenuItem = styled.li`
  margin-bottom: 5px;
`;

export const ToggleButton = styled.button`
  background-color: #5503a5;
  border: none;
  color: white;
  border-radius: 8px;
  width: 28px;
  height: 28px;
  font-weight: bold;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 12px;
  cursor: pointer;

  margin-bottom: 20px;
  margin-right: -13px;
  margin-left: auto;
`;

export const DashFlex = styled.div`
  display: flex;
`;

export const FloatingChatContainer = styled.div`
  position: relative;
  border-radius: 8px;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); */
  padding: 15px;
  z-index: 3;
`;

export const FloatingChatInput = styled.input`
  width: 80%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  z-index: 3;
  position: relative;
`;

export const FloatingResponse = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  text-align: left;
`;

export const LoadingMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    border: 3px solid #888;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const RobocopDiv = styled.div`
  position: absolute;
  top: -120px;
  left: 0px;
  z-index: 1;

  img {
    width: 200px;
  }
`;
