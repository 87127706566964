import styled from 'styled-components';

export const ContainerFooterHome = styled.div`
  background-color: #5503a5;
  color: white;
  display: flex;
  justify-content: space-evenly;
  padding: 50px 50px;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 50px;
  max-width: 1920px;
  margin: 0 auto;
`;

export const FooterSection = styled.div`
  flex: 1;
  min-width: 200px;
  margin: 10px 0;

  img {
    max-width: 200px;
  }
`;

export const FooterSectionInfo = styled.div`
  width: 20%;
  margin: 10px 0;

  @media screen and (max-width: 850px) {
    width: 100%;
    text-align: center;
  }
`;

export const FooterSectionImage = styled.div`
  width: 10%;
  margin: 10px 0;

  @media screen and (max-width: 1250px) {
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }

  img {
    margin-top: -100px;
  }
`;

export const FooterSectionLinks = styled.div`
  width: 15%;
  margin: 10px 0;
  @media screen and (max-width: 850px) {
    width: 100%;
    text-align: center;
  }
  text-align: center;
`;

export const FooterLink = styled.a`
  display: block;
  color: white;
  margin: 5px 0;
  text-decoration: none;

  margin-bottom: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterInfo = styled.p`
  margin: 5px 0 20px;
  margin-right: auto;
  margin-left: auto;

  a {
    color: #7ef14a;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FooterSocial = styled.div`
  display: flex;
  align-items: center;
  width: 20%;

  @media screen and (max-width: 850px) {
    width: 100%;
    justify-content: center;
  }

  a {
    margin: 0 10px;
    img {
      width: 30px;
      height: 30px;
    }
  }
`;
