import styled from 'styled-components';

export const Grid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 1px;
  /* background-color: #ddd; */
`;

export const HourCell = styled.div`
  background-color: #fff;
  height: 50px;
  border-bottom: 1px solid #eee;
  font-size: 0.8rem;
  color: #666;
  text-align: right;
  padding: 5px 10px;
`;

export const MonthCell = styled.div<{ isCurrentMonth: boolean }>`
  background-color: ${(props) => (props.isCurrentMonth ? '#fff' : '#f0f0f0')};
  position: relative;
  padding: 10px;
  height: 120px; /* Aumenta o tamanho do quadrado */
  border: 1px solid #eee;
  font-size: 0.9rem;
  color: ${(props) => (props.isCurrentMonth ? '#333' : '#aaa')};
  display: flex;
  flex-direction: column;
`;

export const DayNumber = styled.div<{ isToday: boolean }>`
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  color: ${(props) => (props.isToday ? '#fff' : '#333')};
  background-color: ${(props) => (props.isToday ? 'limegreen' : 'white')};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CalendarContainer = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    font-size: 1.5rem;
    color: #333;
    margin-left: 80px;
  }

  select {
    padding: 5px 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
`;

export const GridContainer = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns === 1 ? '80px repeat(7, 1fr)' : 'repeat(7, 1fr)'};
  gap: 1px;
`;
export const TimeColumn = styled.div`
  /*   background-color: #f9f9f9;
  border-right: 1px solid #ddd; */
`;

export const TimeCell = styled.div`
  height: 50px;
  text-align: right;
  padding-right: 10px;
  font-size: 0.9rem;
  color: #666;
  line-height: 50px;
`;

export const DayHeader = styled.div<{ isToday: boolean }>`
  background-color: ${(props) =>
    props.isToday ? 'limegreen' : '#fff'}; /* Fundo azul para o dia atual */
  color: ${(props) =>
    props.isToday ? '#fff' : '#333'}; /* Texto branco para contraste */
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: ${(props) =>
    props.isToday ? '8px' : '0'}; /* Bordas arredondadas para o dia atual */
  width: 100px !important;
`;

export const DayCell = styled.div`
  background-color: #fff;
  height: 100px;
  width: 100% !important;
  border-bottom: 1px solid #eee;
`;

export const TableCalendar = styled.div`
  overflow-x: hidden;
  height: 70vh;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #5503a5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5503a5;
    border: 2px solid #5503a5;
  }
`;

export const EventMarker = styled.div`
  background-color: #32cd32;
  color: white;
  font-size: 0.8rem;
  padding: 2px 5px;
  margin: 2px 0;
  border-radius: 3px;
  height: 45%;
  align-items: center;
  align-content: center;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  cursor: pointer;

  transition-duration: 0.3s;

  &:hover {
    background-color: #2ecc71;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
`;

/* export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    font-size: 24px;
    color: #333;
  }
`; */

export const FilterBar = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  select {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
  }

  nav {
    display: flex;
    gap: 10px;

    a {
      font-size: 14px;
      color: #5a5a5a;
      text-decoration: none;

      span {
        color: #6a1b9a;
        margin-left: 5px;
        padding: 5px;
        border-radius: 10px;
        background-color: #e9edf5;
      }
    }
  }
`;

export const SearchInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
`;

export const AddButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #5503a5;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th,
  td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }

  th {
    color: #333;
    font-weight: 600;
  }

  td {
    color: #5a5a5a;
  }

  button {
    background: none;
    border: none;
    color: #6a1b9a;
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }
`;

export const StatusBadge = styled.span<{ color: string }>`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 12px;
  color: ${({ color }) =>
    color === 'green' ? '#14804A' : color === 'orange' ? '#AA5B00' : '#D1293D'};
  background-color: ${({ color }) =>
    color === 'green' ? '#E1FCEF' : color === 'orange' ? '#FCF2E6' : '#FFEDEF'};
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #5a5a5a;
  margin-top: auto;

  div {
    display: flex;
    gap: 10px;

    span {
      display: flex;
      align-items: center;
      padding: 8px 12px;
    }

    button {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      background-color: #fff;

      &:hover {
        color: #333;
      }
    }
  }

  span {
    margin-top: auto;
  }
`;
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  width: 60%;
  max-width: 90%;
  height: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  animation: slideDown 0.3s ease;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;

  &:hover {
    color: #333;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const ModalBody = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Form = styled.div`
  display: block;
  gap: 20px;

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  label {
    margin-top: 10px;
    font-weight: 600;
    color: #555;
  }

  select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 5px;

    &:focus {
      border-color: #6a1b9a;
    }
  }
`;

export const ContainerFlex = styled.main`
  display: flex;
  justify-content: space-around;

  /*   div {
    width: 45%;
  } */
`;

export const TextField = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;

  &:focus {
    border-color: #6a1b9a;
  }
`;

export const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  height: 120px;
  margin-top: 5px;

  &:focus {
    border-color: #6a1b9a;
  }
`;

export const ModalFooter = styled.main`
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  border-top: 1px solid #ddd;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:first-child {
      background-color: #6a1b9a;
      color: #fff;

      &:hover {
        background-color: #ccc;
      }
    }

    &:last-child {
      background-color: #ddd;
      color: #333;
      margin-left: 10px;

      &:hover {
        background-color: #5a0e8e;
      }
    }
  }
`;

/*  */

export const WarningMessage = styled.div`
  background-color: #fff8e1;
  border-left: 4px solid #ffeb3b;
  padding: 10px;
  border-radius: 4px;
  color: #795548;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-weight: bold;
  }

  button {
    background: none;
    border: none;
    font-size: 16px;
    color: #795548;
    cursor: pointer;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  p {
    flex: 1;
    color: #666;
  }

  small {
    color: #999;
  }
`;

export const UploadArea = styled.div`
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #666;
  margin-bottom: 20px;

  span {
    color: #6a1b9a;
    cursor: pointer;
  }

  small {
    display: block;
    margin-top: 5px;
    color: #999;
  }
`;

export const HeaderImport = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FirstColumn = styled.div``;

export const NextEvents = styled.div`
  margin-top: 30px;
  text-align: center;

  cursor: alias;

  div p {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
`;
