import React, { useEffect, useState } from 'react';
import {
  AvatarIcon,
  BellIcon,
  ContainerSuperBar,
  NameBonito,
  SuperBarBarrow,
  SuperBarContent,
} from './style';
import { useSidebar } from '../../context/SidebarContext/SidebarContext';
import bell from './assets/Bell.png';
import avatar_default from './assets/avatar_default.png';

export const SuperBar: React.FC = () => {
  const { isMinimized } = useSidebar();

  const sidebarWidth = isMinimized ? '0px' : '0px';

  const [nome, setNome] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        setNome(decodedToken.nome);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
      }
    }
  }, []);

  return (
    <ContainerSuperBar sidebarWidth={sidebarWidth}>
      <SuperBarBarrow />
      <SuperBarContent>
        <div>Bem-vindo (a),</div>
        <div style={{ display: 'flex' }}>
          <NameBonito>{nome}</NameBonito>!
        </div>
      </SuperBarContent>

      <AvatarIcon>
        <img src={avatar_default} alt="default avatar" />
      </AvatarIcon>

      <BellIcon>
        <img src={bell} alt="bell" />
      </BellIcon>
    </ContainerSuperBar>
  );
};

export default SuperBar;
