import React from 'react';
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  addDays,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

type CalendarProps = {
  selectedDate: Date;
  onDateClick: (date: Date) => void; // Função para capturar cliques
};

const Calendar: React.FC<CalendarProps> = ({ selectedDate, onDateClick }) => {
  const startMonth = startOfMonth(selectedDate);
  const endMonth = endOfMonth(selectedDate);
  const startWeek = startOfWeek(startMonth);
  const days = [];

  let currentDay = startWeek;

  while (currentDay <= endMonth) {
    days.push(currentDay);
    currentDay = addDays(currentDay, 1);
  }

  return (
    <div
      style={{
        width: '300px',
        /* margin: '0 auto', */
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <p>{format(selectedDate, 'MMMM yyyy', { locale: ptBR })}</p>
      <br />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          gap: '5px',
        }}
      >
        {['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'].map((day, index) => (
          <div key={index} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {day}
          </div>
        ))}
        {days.map((day, index) => (
          <div
            key={index}
            style={{
              textAlign: 'center',
              padding: '10px',
              borderRadius: '50%',
              backgroundColor:
                format(day, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')
                  ? 'limegreen'
                  : 'transparent',
              color:
                format(day, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')
                  ? '#fff'
                  : '#000',
              cursor: 'pointer',
            }}
            onClick={() => onDateClick(day)}
          >
            {format(day, 'd')}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
