import { createContext, ReactNode, useContext, useState } from 'react';
import { IProfissional } from '../../interfaces/Profissional/Profissional';

interface ProfissionalContextType {
  profissionais: IProfissional[];
  setProfissionais: React.Dispatch<React.SetStateAction<IProfissional[]>>;
}

const ProfissionalContext = createContext<ProfissionalContextType | undefined>(
  undefined
);

interface ProfissionalProviderProps {
  children: ReactNode;
}

export const ProfissionalProvider = ({
  children,
}: ProfissionalProviderProps) => {
  const [profissionais, setProfissionais] = useState<IProfissional[]>([]);

  return (
    <ProfissionalContext.Provider
      value={{
        profissionais,
        setProfissionais,
      }}
    >
      {children}
    </ProfissionalContext.Provider>
  );
};

export function useProfissional() {
  const context = useContext(ProfissionalContext);
  if (!context) {
    throw new Error(
      'useProfissional must be used within a ProfissionalProvider'
    );
  }
  return context;
}

export default { useProfissional };
