import styled from 'styled-components';

export const ContainerSuperBar = styled.div<{ sidebarWidth: string }>`
  display: flex;
  width: calc(100% - ${({ sidebarWidth }) => sidebarWidth});
  background-color: white;
  padding: 10px;
  box-sizing: border-box;
  transition: width 0.3s ease;
`;

export const SuperBarBarrow = styled.div`
  height: 31.5px;
  width: 2px;
  margin-left: auto;
  background-color: black;
  border-radius: 10px;
  margin-right: 20px;

  margin-top: auto;
  margin-bottom: auto;
`;

export const SuperBarContent = styled.div`
  font-size: 12px;
  margin-right: 10px;

  margin-top: auto;
  margin-bottom: auto;
`;

export const NameBonito = styled.p`
  color: #5503a5;
  font-weight: bold;
`;

export const BellIcon = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;

  margin-right: 30px;
`;

export const AvatarIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
