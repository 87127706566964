import React, { useState } from 'react';
import {
  startOfWeek,
  addDays,
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  endOfWeek,
  isSameMonth,
  isToday,
  isSameDay,
  isWithinInterval,
  parseISO,
} from 'date-fns';
import {
  CalendarContainer,
  DayCell,
  DayHeader,
  DayNumber,
  CloseButton,
  EventMarker,
  GridContainer,
  Header,
  HourCell,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  MonthCell,
  TableCalendar,
  TimeCell,
  TimeColumn,
} from './style';
import { ptBR } from 'date-fns/locale';

interface Agendamento {
  id: number;
  data_hora: string; // ISO string
  hora_inicio: string;
  hora_fim: string;
  cliente: { nome: string };
  servico: { nome: string };
  profissional: { nome: string };
  unidade: { nome: string };
  especialidade: { nome: string };
  observacoes: string;
}

interface BigCalendarProps {
  agendamentos: Agendamento[];
}

const BigCalendar: React.FC<BigCalendarProps> = ({ agendamentos }) => {
  if (agendamentos.length == 0) {
    return;
  }

  const [view, setView] = useState<'week' | 'month'>('month');
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Agendamento | null>(null);

  const openModal = (event: Agendamento) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setIsModalOpen(false);
  };

  const startWeek = startOfWeek(selectedDate, { weekStartsOn: 1 });
  const startMonth = startOfMonth(selectedDate);
  const endMonth = endOfMonth(selectedDate);

  const weekDays = Array.from({ length: 7 }, (_, i) => addDays(startWeek, i));
  const hours = Array.from(
    { length: 24 },
    (_, i) => `${i.toString().padStart(2, '0')}:00`
  );
  const startCalendar = startOfWeek(startMonth, { weekStartsOn: 0 });
  const endCalendar = endOfWeek(endMonth, { weekStartsOn: 0 });

  const calendarDays = eachDayOfInterval({
    start: startCalendar,
    end: endCalendar,
  });

  return (
    <CalendarContainer>
      <Header>
        <h2>{format(selectedDate, 'MMMM yyyy', { locale: ptBR })}</h2>
        <select
          value={view}
          onChange={(e) => setView(e.target.value as 'week' | 'month')}
        >
          <option value="week">Semana</option>
          <option value="month">Mês</option>
        </select>
      </Header>

      <TableCalendar>
        {view === 'week' && (
          <>
            <GridContainer columns={1}>
              <TimeColumn />
              {weekDays.map((day) => (
                <DayHeader key={day.toString()} isToday={isToday(day)}>
                  {format(day, 'EEE, dd', { locale: ptBR })}
                </DayHeader>
              ))}

              {hours.map((hour, hourIndex) => (
                <React.Fragment key={hour}>
                  <TimeColumn>
                    <TimeCell>{hour}</TimeCell>
                  </TimeColumn>
                  {weekDays.map((day) => {
                    // Verificar agendamentos para o horário
                    const eventsAtThisTime = agendamentos.filter((ag) => {
                      // const eventDate = parseISO(ag.data_hora); // Data base do evento
                      const eventDate = ag.data_hora;
                      const [startHour, startMinute] = ag.hora_inicio
                        .split(':')
                        .map(Number);
                      const [endHour, endMinute] = ag.hora_fim
                        .split(':')
                        .map(Number);

                      const eventStartTime = new Date(eventDate);
                      const eventEndTime = new Date(eventDate);

                      // Ajustar os horários de início e fim do evento
                      eventStartTime.setHours(startHour, startMinute, 0, 0);
                      eventEndTime.setHours(endHour, endMinute, 0, 0);

                      const cellStartTime = new Date(day);
                      cellStartTime.setHours(hourIndex, 0, 0, 0); // Hora inicial da célula

                      const cellEndTime = new Date(cellStartTime);
                      cellEndTime.setMinutes(59, 59, 999); // Hora final da célula

                      // Verificar se o evento ocorre dentro do intervalo da célula
                      return (
                        isSameDay(eventDate, day) &&
                        isWithinInterval(eventStartTime, {
                          start: cellStartTime,
                          end: cellEndTime,
                        })
                      );
                    });

                    return (
                      <DayCell key={`${hour}-${day.toString()}`}>
                        {eventsAtThisTime.map((event) => (
                          <EventMarker
                            key={event.id}
                            onClick={() => openModal(event)}
                          >
                            {event.cliente.nome}
                          </EventMarker>
                        ))}
                      </DayCell>
                    );
                  })}
                </React.Fragment>
              ))}
            </GridContainer>
          </>
        )}

        {view === 'month' && (
          <>
            <GridContainer columns={7}>
              {['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'].map(
                (day, index) => (
                  <DayHeader key={`header-${index}`} isToday={false}>
                    {day}
                  </DayHeader>
                )
              )}
            </GridContainer>

            <GridContainer columns={7}>
              {calendarDays.map((day) => {
                // Verificar agendamentos para o dia
                const eventsAtThisDay = agendamentos.filter((ag) =>
                  // isSameDay(parseISO(ag.data_hora), day)
                  isSameDay(ag.data_hora, day)
                );

                return (
                  <MonthCell
                    key={day.toString()}
                    isCurrentMonth={isSameMonth(day, selectedDate)}
                  >
                    <DayNumber isToday={isToday(day)}>
                      {format(day, 'dd')}
                    </DayNumber>
                    {eventsAtThisDay.map((event) => (
                      <EventMarker
                        key={event.id}
                        onClick={() => openModal(event)}
                      >
                        {event.cliente.nome}
                      </EventMarker>
                    ))}
                  </MonthCell>
                );
              })}
            </GridContainer>
          </>
        )}
      </TableCalendar>

      {isModalOpen && selectedEvent && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <h3>Detalhes do Evento</h3>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>
            <ModalBody>
              <h4>Informações do Evento</h4>
              <p>
                <strong>Profissonal:</strong> {selectedEvent.profissional.nome}
              </p>
              <p>
                <strong>Unidade:</strong> {selectedEvent.unidade.nome}
              </p>
              <p>
                <strong>Cliente:</strong> {selectedEvent.cliente.nome}
              </p>
              <p>
                <strong>Serviço:</strong> {selectedEvent.servico.nome}
              </p>
              <p>
                <strong>Data:</strong>{' '}
                {
                  // format(parseISO(selectedEvent.data_hora), 'dd/MM/yyyy')
                }
                {format(selectedEvent.data_hora, 'dd/MM/yyyy')}
              </p>
              <p>
                <strong>Horário:</strong> {selectedEvent.hora_inicio} -{' '}
                {selectedEvent.hora_fim}
              </p>
              <p>
                <strong>Observações:</strong>{' '}
                {selectedEvent.observacoes || 'Nenhuma'}
              </p>
            </ModalBody>
            <ModalFooter>
              <button onClick={closeModal}>Fechar</button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
    </CalendarContainer>
  );
};

export default BigCalendar;
