import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import {
  Container,
  Title,
  Text,
  FormContainer,
  Button,
  Input,
  Select,
  Footer,
  TimeSlot,
  TimeContainer,
  CheckboxContainer,
  CheckboxLabel,
} from './style';

import logo from './assets/barber.jpg';
import logoHaaify from '../../assets/logo.svg';
import axios from 'axios';
import { toast } from 'react-toastify';

const Barbearia: React.FC = () => {
  const API_URL =
    'https://barbearia-api-zmwlc.ondigitalocean.app/api/barbearia/generate-data';

  const [step, setStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    cpf: '',
    date: '',
    unidade: '',
    horario: '',
    servicos: [] as string[],
  });

  const validateStep = () => {
    switch (step) {
      case 0:
        if (!formData.fullName.trim()) {
          toast.error('Por favor, preencha o nome completo.');
          return false;
        }
        break;
      case 1:
        if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
          toast.error('Por favor, preencha um e-mail válido.');
          return false;
        }
        break;
      case 2:
        if (!formData.cpf.trim() || formData.cpf.length < 14) {
          toast.error('Por favor, preencha um CPF válido.');
          return false;
        }
        break;
      case 3:
        if (!formData.date.trim()) {
          toast.error('Por favor, selecione uma data.');
          return false;
        }
        break;
      case 4:
        if (!formData.unidade.trim()) {
          toast.error('Por favor, selecione uma unidade.');
          return false;
        }
        break;
      case 5:
        if (formData.servicos.length === 0) {
          toast.error('Por favor, selecione pelo menos um serviço.');
          return false;
        }
        break;
      case 6:
        if (!formData.horario.trim()) {
          toast.error('Por favor, selecione um horário.');
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      servicos: checked
        ? [...prevData.servicos, value]
        : prevData.servicos.filter((servico) => servico !== value),
    }));
  };

  const handleNext = () => {
    if (validateStep()) {
      setStep(step + 1);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (validateStep()) {
      try {
        console.log(formData);
        const response = await axios.post(`${API_URL}`, formData);

        setIsSubmitted(true);
        toast.success('Agendamento realizado com sucesso!');
      } catch (error) {
        console.error('Erro ao enviar o formulário:', error);
        alert('Ocorreu um erro ao enviar o agendamento. Tente novamente.');
      }
    }
  };

  if (isSubmitted) {
    return (
      <>
        <Container>
          <br />
          <img alt="Barbearia Logo" src={logo} />
          <Title>Barbearia Seu Zé</Title>
          <br />
          <Title>Agendamento Enviado!</Title>
          <br />
          <Text>
            Obrigado por escolher a Barbearia Seu Zé. Seu agendamento foi
            realizado com sucesso.
          </Text>
          <br />
          <Button onClick={() => window.location.reload()}>
            Fazer Novo Agendamento
          </Button>
        </Container>
        <Footer>
          <p>Desenvolvido por Haaify</p>
          <img src={logoHaaify} alt="Logo" />
        </Footer>
      </>
    );
  }

  return (
    <>
      <Container>
        <br />
        <img alt="Barbearia Logo" src={logo} />
        <Title>Barbearia Seu Zé</Title>
        <br />
        <Text>
          Aqui você pode agendar cabeleireiros, tratamentos e outros serviços.
        </Text>
        <br />
        <FormContainer>
          {step === 0 && (
            <>
              <Text>Nome Completo</Text>
              <Input
                type="text"
                name="fullName"
                placeholder="Digite seu nome completo..."
                value={formData.fullName}
                onChange={handleChange}
              />
              <Button onClick={handleNext}>Próximo</Button>
            </>
          )}
          {step === 1 && (
            <>
              <Text>E-mail</Text>
              <Input
                type="email"
                name="email"
                placeholder="Digite seu e-mail..."
                value={formData.email}
                onChange={handleChange}
              />
              <Button onClick={handleNext}>Próximo</Button>
            </>
          )}
          {step === 2 && (
            <>
              <Text>CPF</Text>
              <InputMask
                mask="999.999.999-99"
                name="cpf"
                placeholder="Digite seu CPF..."
                value={formData.cpf}
                onChange={handleChange}
              >
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
              <Button onClick={handleNext}>Próximo</Button>
            </>
          )}
          {step === 3 && (
            <>
              <Text>Data de Agendamento</Text>
              <Input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
              />
              <Button onClick={handleNext}>Próximo</Button>
            </>
          )}
          {step === 4 && (
            <>
              <Text>Selecione a Unidade</Text>
              <Select
                name="unidade"
                value={formData.unidade}
                onChange={handleChange}
              >
                <option value="">Selecione</option>
                <option value="Unidade 1">Unidade 1</option>
                <option value="Unidade 2">Unidade 2</option>
                <option value="Unidade 3">Unidade 3</option>
              </Select>
              <Button onClick={handleNext}>Próximo</Button>
            </>
          )}
          {step === 5 && (
            <>
              <Text>Serviços</Text>
              <CheckboxContainer>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    value="Corte de Cabelo"
                    checked={formData.servicos.includes('Corte de Cabelo')}
                    onChange={handleCheckboxChange}
                  />
                  Corte de Cabelo - R$ 40,00
                </CheckboxLabel>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    value="Barba"
                    checked={formData.servicos.includes('Barba')}
                    onChange={handleCheckboxChange}
                  />
                  Barba - R$ 20,00
                </CheckboxLabel>
              </CheckboxContainer>
              <Button onClick={handleNext}>Próximo</Button>
            </>
          )}
          {step === 6 && (
            <>
              <Text>Horário de Agendamento</Text>
              <TimeContainer>
                {Array.from({ length: 9 }, (_, index) => {
                  const hour = 9 + index;
                  const time = `${hour.toString().padStart(2, '0')}:00`;
                  return (
                    <TimeSlot
                      key={time}
                      onClick={() =>
                        setFormData({ ...formData, horario: time })
                      }
                      selected={formData.horario === time}
                    >
                      {time}
                    </TimeSlot>
                  );
                })}
              </TimeContainer>
              <Button onClick={handleSubmit}>Concluir</Button>
            </>
          )}
        </FormContainer>
      </Container>
      <Footer>
        <p>Desenvolvido por Haaify</p>
        <img src={logoHaaify} alt="Logo" />
      </Footer>
    </>
  );
};

export default Barbearia;
