import { createContext, ReactNode, useContext, useState } from 'react';
import { IUnidade } from '../../interfaces/Unidade/Unidade';

interface UnidadeContextType {
  unidades: IUnidade[];
  setUnidades: React.Dispatch<React.SetStateAction<IUnidade[]>>;
}

const UnidadeContext = createContext<UnidadeContextType | undefined>(undefined);

interface UnidadeProviderProps {
  children: ReactNode;
}

export const UnidadeProvider = ({ children }: UnidadeProviderProps) => {
  const [unidades, setUnidades] = useState<IUnidade[]>([]);

  return (
    <UnidadeContext.Provider value={{ unidades, setUnidades }}>
      {children}
    </UnidadeContext.Provider>
  );
};

export function useUnidade() {
  const context = useContext(UnidadeContext);
  if (!context) {
    throw new Error('Must be used within a UnidadeProvider');
  }
  return context;
}

export default { useUnidade };
