import { createContext, ReactNode, useContext, useState } from 'react';
import { Cargo } from '../../interfaces/Cargo/Cargo';

interface CargoContextType {
  cargos: Cargo[];
  setCargos: React.Dispatch<React.SetStateAction<Cargo[]>>;
}

const CargoContext = createContext<CargoContextType | undefined>(undefined);

interface CargoProviderProps {
  children: ReactNode;
}

export const CargoProvider = ({ children }: CargoProviderProps) => {
  const [cargos, setCargos] = useState<Cargo[]>([]);

  return (
    <CargoContext.Provider value={{ cargos, setCargos }}>
      {children}
    </CargoContext.Provider>
  );
};

export function useCargo() {
  const context = useContext(CargoContext);
  if (!context) {
    throw new Error('useCargo must be used within a CargoProvider');
  }
  return context;
}

export default { useCargo };
