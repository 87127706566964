import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  FilterBar,
  AddButton,
  Table,
  Pagination,
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContainer,
  Tab,
  Form,
  TextField,
  TextArea,
} from './style';

import lapis from './assets/lapis.png';
import { useGestaoEquipe } from '../../../context/GestaoEquipe/GestaoEquipeContext';
import { toast } from 'react-toastify';
import ModalDelete from '../../../hooks/ModalDelete/ModalDelete';

const TabelaUnidadeGestaoEquipe: React.FC = () => {
  const {
    unidades,
    getUnidades,
    handleAddUnidade,
    handleEditUnidade,
    handleDeleteUnidade,
  } = useGestaoEquipe();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('addClient');

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [endereco, setEndereco] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    getUnidades();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'nome':
        setName(value);
        break;
      case 'endereco':
        setEndereco(value);
        break;
      case 'telefone':
        setTelefone(value);
        break;
      case 'email':
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await handleAddUnidade({
        nome: name,
        endereco,
        telefone,
        email,
      });
      closeModal();
      toast.success('Unidade criada com sucesso!');
      setId('');
      setName('');
      setEndereco('');
      setTelefone('');
      setEmail('');
    } catch (error) {
      console.error('Erro ao adicionar Unidade:', error);
    }
  };

  const openModalEdit = (unidadeId: string) => {
    const unidadeEdit = unidades.find((u) => u.id === unidadeId);
    if (!unidadeEdit) return;

    setId(unidadeEdit.id);
    setName(unidadeEdit.nome);
    setEndereco(unidadeEdit.endereco || '');
    setTelefone(unidadeEdit.telefone || '');
    setEmail(unidadeEdit.email || '');

    openModal();
  };

  const handleSubmitEdit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await handleEditUnidade(id, {
        nome: name,
        endereco,
        telefone,
        email,
      });
      closeModal();
      toast.success('Unidade editada com sucesso!');
      setId('');
      setName('');
      setEndereco('');
      setTelefone('');
      setEmail('');
    } catch (error) {
      console.error('Erro ao editar unidade:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await handleDeleteUnidade(id);
      closeModal();
      toast.success('Unidade deletada com sucesso!');
      setId('');
      setName('');
      setEndereco('');
      setTelefone('');
      setEmail('');
    } catch (error) {
      console.error('Erro ao deletar unidade:', error);
    }
  };

  /* ======> DELTE <====== */

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [message, setMessage] = useState('');

  const openDeleteModal = () => {
    setMessage(`Deseja excluir a unidade ${name} ?`);

    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    handleDelete();

    setIsDeleteModalOpen(false);
  };

  return (
    <Container>
      <Header>
        <div>
          <h3>Unidades</h3>
          <br />
          <p>
            Crie as Unidades e organize seus profissionais para uma gestão
            completa.
          </p>
        </div>
      </Header>

      <Header>
        <FilterBar>
          <p>
            <b>Unidades Cadastradas:</b>
          </p>
        </FilterBar>

        <AddButton
          onClick={() => {
            setId('');
            setName('');
            setEndereco('');
            setTelefone('');
            setEmail('');
            openModal();
          }}
        >
          Adicionar unidade +
        </AddButton>
      </Header>

      <Table>
        <thead>
          <tr>
            <th>Nome da Unidade</th>
            <th>Endereço</th>
            <th>Telefone</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {unidades.length === 0 ? (
            <tr>
              <td colSpan={4}>
                <h2>Nenhuma unidade encontrada.</h2>
              </td>
            </tr>
          ) : (
            unidades.map((unidade) => (
              <tr key={unidade.id}>
                <td>{unidade.nome}</td>
                <td>{unidade.endereco}</td>
                <td>{unidade.telefone}</td>
                <td>
                  <button onClick={() => openModalEdit(unidade.id)}>
                    <img src={lapis} alt="Editar" />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination>
        <span>1-2 de 2</span>
        <div>
          <span>Itens por página: 15</span>
          <button>{'<'}</button>
          <span>1 / 2</span>
          <button>{'>'}</button>
        </div>
      </Pagination>

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <TabContainer>
                <Tab
                  active={activeTab === 'addClient'}
                  onClick={() => setActiveTab('addClient')}
                >
                  {id ? 'Editar Unidade' : 'Adicionar Unidade'}
                </Tab>
              </TabContainer>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>

            <ModalBody>
              {activeTab === 'addClient' && (
                <Form>
                  <div>
                    <label>Nome da Unidade</label>
                    <TextField
                      value={name}
                      onChange={handleChange}
                      name="nome"
                      type="text"
                      placeholder="Nome da Unidade"
                    />
                    <label>Endereço</label>
                    <TextField
                      value={endereco}
                      onChange={handleChange}
                      name="endereco"
                      type="text"
                      placeholder="Endereço"
                    />
                  </div>
                  <div>
                    <label>Email</label>
                    <TextField
                      name="email"
                      value={email}
                      onChange={handleChange}
                      type="email"
                      placeholder="Email da Unidade"
                    />
                    <label>Telefone</label>
                    <TextField
                      value={telefone}
                      onChange={handleChange}
                      name="telefone"
                      type="text"
                      placeholder="Telefone"
                    />
                  </div>
                </Form>
              )}
            </ModalBody>

            <ModalFooter>
              {id ? (
                <>
                  <button onClick={handleSubmitEdit}>Editar Unidade</button>
                  <button onClick={openDeleteModal} className="button-delete">
                    Deletar Unidade
                  </button>
                </>
              ) : (
                <button onClick={handleSubmit}>Adicionar Unidade</button>
              )}
              <button onClick={closeModal}>Cancelar</button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
      <ModalDelete
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        title="Excluir Unidade"
        message={message}
      />
    </Container>
  );
};

export default TabelaUnidadeGestaoEquipe;
