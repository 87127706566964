import React, { useState } from 'react';
import {
  Container,
  Header,
  FilterBar,
  AddButton,
  Table,
  Pagination,
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContainer,
  Tab,
  Form,
  TextField,
} from './style';

import lapis from './assets/lapis.png';
import { useGestaoEquipe } from '../../../context/GestaoEquipe/GestaoEquipeContext';
import { toast } from 'react-toastify';
import ModalDelete from '../../../hooks/ModalDelete/ModalDelete';

const TabelaProfissionalGestaoEquipe = () => {
  const {
    profissionais,
    especialidades,
    unidades,
    handleAddProfissional,
    handleEditProfissional,
    handleDeleteProfissional,
  } = useGestaoEquipe();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('addClient');

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [selectedEspecialidade, setSelectedEspecialidade] = useState('');
  const [selectedUnidade, setSelectedUnidade] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'nome':
        setName(value);
        break;
      case 'telefone':
        setTelefone(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'senha':
        setSenha(value);
        break;
      default:
        break;
    }
  };

  const handleChangeOptionEspecialidade = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedEspecialidade(e.target.value);
  };

  const handleChangeOptionUnidade = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedUnidade(e.target.value);
  };

  const openModalEdit = (profissionalId: string) => {
    const profissionalEdit = profissionais.find((p) => p.id === profissionalId);
    if (!profissionalEdit) return;

    setId(profissionalEdit.id);
    setName(profissionalEdit.nome);
    setEmail(profissionalEdit.email);
    setTelefone(profissionalEdit.telefone ?? '');
    setSelectedEspecialidade(profissionalEdit.especialidade_id ?? '');
    setSelectedUnidade(profissionalEdit.unidade_id ?? '');
    setSenha('');

    openModal();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = {
      nome: name,
      email,
      telefone,
      senha,
      unidade_id: selectedUnidade,
      especialidade_id: selectedEspecialidade,
    };

    try {
      await handleAddProfissional(data);
      closeModal();

      toast.success('Profissional adicionado com sucesso!');

      setId('');
      setName('');
      setTelefone('');
      setEmail('');
      setSenha('');
      setSelectedEspecialidade('');
      setSelectedUnidade('');
    } catch (error) {
      console.error('Erro ao adicionar profissional:', error);
    }
  };

  const handleSubmitEdit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = {
      nome: name,
      email,
      telefone,
      unidade_id: selectedUnidade,
      especialidade_id: selectedEspecialidade,
    };

    try {
      await handleEditProfissional(id, data);
      closeModal();

      toast.success('Profissional atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao editar profissional:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await handleDeleteProfissional(id);

      toast.success('Profissional deletado com sucesso!');

      closeModal();
    } catch (error) {
      console.error('Erro ao deletar profissional:', error);
    }
  };

  /* ======> DELTE <====== */

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [message, setMessage] = useState('');

  const openDeleteModal = () => {
    setMessage(`Deseja excluir o profissional ${name} ?`);

    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    handleDelete();

    setIsDeleteModalOpen(false);
    closeModal();
  };

  return (
    <Container>
      <Header>
        <div>
          <h3>Membros da sua equipe</h3>
          <br />
          <p>
            Adicione membros, altere suas informações, defina o modelo de acesso
            para cada profissional e configure os serviços oferecidos.
          </p>
        </div>
      </Header>

      <Header>
        <FilterBar />
        <AddButton
          onClick={() => {
            setId('');
            setName('');
            setEmail('');
            setSenha('');
            setTelefone('');
            setSelectedEspecialidade('');
            setSelectedUnidade('');
            openModal();
          }}
        >
          Adicionar profissional +
        </AddButton>
      </Header>

      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Telefone</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {profissionais.length === 0 ? (
            <>
              <br />
              <h2>Nenhum profissional encontrado.</h2>
            </>
          ) : (
            profissionais.map((profissional) => (
              <tr key={profissional.id}>
                <td>{profissional.nome}</td>
                <td>{profissional.email}</td>
                <td>{profissional.telefone}</td>
                <td>
                  <button onClick={() => openModalEdit(profissional.id)}>
                    <img src={lapis} alt="Editar" />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination>
        <span>1-2 de 2</span>
        <div>
          <span>Itens por página: 15</span>
          <button>{'<'}</button>
          <span>1 / 2</span>
          <button>{'>'}</button>
        </div>
      </Pagination>

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <TabContainer>
                <Tab
                  active={activeTab === 'addClient'}
                  onClick={() => setActiveTab('addClient')}
                >
                  Adicionar Profissional
                </Tab>
              </TabContainer>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>
            <ModalBody>
              {activeTab === 'addClient' && (
                <Form>
                  <div>
                    <label>Nome completo</label>
                    <TextField
                      name="nome"
                      value={name}
                      onChange={handleChange}
                      type="text"
                      placeholder="Nome do profissional"
                    />
                    <label>Email</label>
                    <TextField
                      name="email"
                      value={email}
                      onChange={handleChange}
                      type="email"
                      placeholder="Digite o Email"
                    />
                    <label>Telefone</label>
                    <TextField
                      name="telefone"
                      value={telefone}
                      onChange={handleChange}
                      type="tel"
                      placeholder="Digite o Telefone"
                    />
                    {id !== '' ? null : (
                      <>
                        <label>Senha</label>
                        <TextField
                          name="senha"
                          value={senha}
                          onChange={handleChange}
                          type="password"
                          placeholder="Digite a senha"
                        />
                      </>
                    )}
                  </div>
                  <div>
                    <label>Especialidade</label>
                    <select
                      value={selectedEspecialidade}
                      onChange={handleChangeOptionEspecialidade}
                    >
                      <option value="">Selecione a especialidade</option>
                      {especialidades.map((esp) => (
                        <option key={esp.id} value={esp.id}>
                          {esp.nome}
                        </option>
                      ))}
                    </select>

                    <label>Unidade</label>
                    <select
                      value={selectedUnidade}
                      onChange={handleChangeOptionUnidade}
                    >
                      <option value="">Selecione a unidade</option>
                      {unidades.map((uni) => (
                        <option key={uni.id} value={uni.id}>
                          {uni.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </Form>
              )}
            </ModalBody>
            <ModalFooter>
              {id !== '' ? (
                <>
                  <button type="submit" onClick={handleSubmitEdit}>
                    Editar Profissional
                  </button>
                  <button
                    type="button"
                    className="button-delete"
                    onClick={openDeleteModal}
                  >
                    Deletar Profissional
                  </button>
                </>
              ) : (
                <button type="submit" onClick={handleSubmit}>
                  Adicionar Profissional
                </button>
              )}
              <button onClick={closeModal}>Cancelar</button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
      <ModalDelete
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        title="Excluir Profissional"
        message={message}
      />
    </Container>
  );
};

export default TabelaProfissionalGestaoEquipe;
