import {
  ContainerMission,
  ContainerMissionImage,
  ContainerMissionText,
  MissionButtonContainer,
  MissionDescription,
  MissionImageTag,
  MissionTitle,
} from './style';
import MissionImage from '../assets/mission_image.png';
import Intersect from '../assets/ico/Intersect.svg';

const Mission: React.FC = () => {
  return (
    <ContainerMission id="who">
      <ContainerMissionText>
        <MissionTitle>Nossa Missão</MissionTitle>
        <MissionDescription>
          <span>
            Nossa missão é simples: criar soluções que tornem a comunicação mais
            eficiente.
          </span>
        </MissionDescription>
        <MissionDescription>
          Acreditamos que a tecnologia deve melhorar a vida das pessoas, e é por
          isso que oferecemos soluções de atendimento e automação de alta
          qualidade, com excelente custo-benefício.{' '}
          <span>O futuro da interação está aqui e é para todos.</span> Vamos
          ajudar você a se conectar melhor com seus públicos, através de
          soluções personalizadas.
        </MissionDescription>
        <MissionButtonContainer>
          <a href="https://wa.link/h5d1k4">
            <button>
              Agendar reunião <img title="Intersect" src={Intersect} />
            </button>
          </a>
        </MissionButtonContainer>
      </ContainerMissionText>
      <ContainerMissionImage>
        <MissionImageTag src={MissionImage} alt="Mission Image" />
      </ContainerMissionImage>
    </ContainerMission>
  );
};

export default Mission;
