import React from 'react';

const Dashboard: React.FC = () => {
  return (
    <>
      <iframe
        src="https://lookerstudio.google.com/embed/reporting/90a580d5-96fa-4347-b376-b65f1de4dfed/page/p_5d38ohjkhd"
        frameBorder="0"
        style={{ border: 0, width: '100%', height: '100%' }}
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </>
  );
};

export default Dashboard;
