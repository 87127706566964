import { Outlet } from 'react-router-dom';
import Navbar from '../../pages/Dashboard/components/Navbar/Navbar';
import { DashFlex } from '../../pages/Dashboard/components/Navbar/style';

import { UnidadeProvider } from '../../context/UnidadeContext/UnidadeContext';
import { AgendamentoProvider } from '../../context/AgendamentoContext/AgendamentoContext';
import { CargoProvider } from '../../context/CargoContext/CargoContext';
import { ClienteProvider } from '../../context/ClienteContext/ClienteContext';
import { EspecialidadeProvider } from '../../context/EspecialidadeContext/EspecialidadeContext';
import { ProfissionalProvider } from '../../context/ProfissionalContext/ProfissionalContext';
import { ServicoProvider } from '../../context/ServicoContext/ServicoContext';
import SuperBar from '../SuperBar/SuperBar';
import { SidebarProvider } from '../../context/SidebarContext/SidebarContext';
import { GestaoEquipeProvider } from '../../context/GestaoEquipe/GestaoEquipeContext';

function ProtectedLayout() {
  return (
    <AgendamentoProvider>
      <CargoProvider>
        <ClienteProvider>
          <EspecialidadeProvider>
            <ProfissionalProvider>
              <ServicoProvider>
                <UnidadeProvider>
                  <GestaoEquipeProvider>
                    <SidebarProvider>
                      <DashFlex>
                        <Navbar />
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <SuperBar />
                          <Outlet />
                        </div>
                      </DashFlex>
                    </SidebarProvider>
                  </GestaoEquipeProvider>
                </UnidadeProvider>
              </ServicoProvider>
            </ProfissionalProvider>
          </EspecialidadeProvider>
        </ClienteProvider>
      </CargoProvider>
    </AgendamentoProvider>
  );
}

export default ProtectedLayout;
