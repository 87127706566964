import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import axiosInstance from '../../utils/axiosInstance';

interface IProfissional {
  id: string;
  nome: string;
  email: string;
  telefone: string;
  unidade_id?: string;
  especialidade_id?: string;
}

interface IEspecialidade {
  id: string;
  nome: string;
  descricao: string;
}

interface IUnidade {
  id: string;
  nome: string;
  endereco?: string;
  telefone?: string;
  email?: string;
}

interface IServico {
  id: string;
  nome: string;
  descricao?: string;
  duracao?: string;
  preco?: string;
  especialidade_id?: string;
}

interface IServicoInput {
  nome: string;
  descricao?: string;
  duracao?: string;
  preco?: string;
  especialidade_id?: string;
}

interface IProfissionalInput {
  nome: string;
  email: string;
  telefone: string;
  senha?: string;
  unidade_id?: string;
  especialidade_id?: string;
}

interface IEspecialidadeInput {
  nome: string;
  descricao: string;
}

interface IUnidadeInput {
  nome: string;
  endereco?: string;
  telefone?: string;
  email?: string;
}

interface GestaoEquipeContextProps {
  profissionais: IProfissional[];
  especialidades: IEspecialidade[];
  unidades: IUnidade[];
  services: IServico[];
  getServicos: () => Promise<void>;
  getProfissional: () => Promise<void>;
  getEspecialidades: () => Promise<void>;
  getUnidades: () => Promise<void>;

  // Profissional
  handleAddProfissional: (data: IProfissionalInput) => Promise<void>;
  handleEditProfissional: (
    id: string,
    data: IProfissionalInput
  ) => Promise<void>;
  handleDeleteProfissional: (id: string) => Promise<void>;

  handleAddEspecialidade: (data: IEspecialidadeInput) => Promise<void>;
  handleEditEspecialidade: (
    id: string,
    data: IEspecialidadeInput
  ) => Promise<void>;
  handleDeleteEspecialidade: (id: string) => Promise<void>;

  handleAddServico: (data: IServicoInput) => Promise<void>;
  handleEditServico: (id: string, data: IServicoInput) => Promise<void>;
  handleDeleteServico: (id: string) => Promise<void>;

  handleAddUnidade: (data: IUnidadeInput) => Promise<void>;
  handleEditUnidade: (id: string, data: IUnidadeInput) => Promise<void>;
  handleDeleteUnidade: (id: string) => Promise<void>;
}

const GestaoEquipeContext = createContext<GestaoEquipeContextProps | undefined>(
  undefined
);

interface GestaoEquipeProviderProps {
  children: ReactNode;
}

export function GestaoEquipeProvider({ children }: GestaoEquipeProviderProps) {
  const [profissionais, setProfissionais] = useState<IProfissional[]>([]);
  const [especialidades, setEspecialidades] = useState<IEspecialidade[]>([]);
  const [unidades, setUnidades] = useState<IUnidade[]>([]);
  const [services, setServices] = useState<IServico[]>([]);

  const API_PROFISSIONAL = '/profissional/';
  const API_ESPECIALIDADE = '/especialidade/';
  const API_UNIDADE = '/unidade/';
  const API_SERVICO = '/servico/';

  async function getProfissional() {
    try {
      const response = await axiosInstance.get(API_PROFISSIONAL);
      setProfissionais(response.data);
    } catch (error) {
      console.error('Erro ao buscar profissionais:', error);
    }
  }

  async function getEspecialidades() {
    try {
      const response = await axiosInstance.get(API_ESPECIALIDADE);
      setEspecialidades(response.data);
    } catch (error) {
      console.error('Erro ao buscar especialidades:', error);
    }
  }

  async function getUnidades() {
    try {
      const response = await axiosInstance.get(API_UNIDADE);
      setUnidades(response.data);
    } catch (error) {
      console.error('Erro ao buscar unidades:', error);
    }
  }

  async function getServicos() {
    try {
      const response = await axiosInstance.get(API_SERVICO);
      setServices(response.data);
    } catch (error) {
      console.error('Erro ao buscar serviços:', error);
    }
  }

  // ============================
  //         PROFISSIONAL
  // ============================
  async function handleAddProfissional(data: IProfissionalInput) {
    try {
      await axiosInstance.post(API_PROFISSIONAL, data);
      getProfissional();
    } catch (error) {
      console.error('Erro ao adicionar profissional:', error);
      throw error;
    }
  }

  async function handleEditProfissional(id: string, data: IProfissionalInput) {
    try {
      await axiosInstance.put(`${API_PROFISSIONAL}${id}`, data);
      getProfissional();
    } catch (error) {
      console.error('Erro ao editar profissional:', error);
      throw error;
    }
  }

  async function handleDeleteProfissional(id: string) {
    try {
      await axiosInstance.delete(`${API_PROFISSIONAL}${id}`);
      getProfissional();
    } catch (error) {
      console.error('Erro ao deletar profissional:', error);
      throw error;
    }
  }

  // ============================
  //         ESPECIALIDADE
  // ============================
  async function handleAddEspecialidade(data: IEspecialidadeInput) {
    try {
      await axiosInstance.post(API_ESPECIALIDADE, data);
      getEspecialidades();
    } catch (error) {
      console.error('Erro ao adicionar especialidade:', error);
      throw error;
    }
  }

  async function handleEditEspecialidade(
    id: string,
    data: IEspecialidadeInput
  ) {
    try {
      await axiosInstance.put(`${API_ESPECIALIDADE}${id}`, data);
      getEspecialidades();
    } catch (error) {
      console.error('Erro ao editar especialidade:', error);
      throw error;
    }
  }

  async function handleDeleteEspecialidade(id: string) {
    try {
      await axiosInstance.delete(`${API_ESPECIALIDADE}${id}`);
      getEspecialidades();
    } catch (error) {
      console.error('Erro ao deletar especialidade:', error);
      throw error;
    }
  }

  // ============================
  //         SERVIÇOS
  // ============================
  async function handleAddServico(data: IServicoInput) {
    try {
      await axiosInstance.post(API_SERVICO, data);
      getServicos();
    } catch (error) {
      console.error('Erro ao adicionar serviço:', error);
      throw error;
    }
  }

  async function handleEditServico(id: string, data: IServicoInput) {
    try {
      await axiosInstance.put(`${API_SERVICO}${id}`, data);
      getServicos();
    } catch (error) {
      console.error('Erro ao editar serviço:', error);
      throw error;
    }
  }

  async function handleDeleteServico(id: string) {
    try {
      await axiosInstance.delete(`${API_SERVICO}${id}`);
      getServicos();
    } catch (error) {
      console.error('Erro ao deletar serviço:', error);
      throw error;
    }
  }

  // =====================
  //   FUNÇÕES UNIDADES
  // =====================
  async function handleAddUnidade(data: IUnidadeInput) {
    try {
      await axiosInstance.post(API_UNIDADE, data);
      await getUnidades();
    } catch (error) {
      console.error('Erro ao adicionar unidade:', error);
      throw error;
    }
  }

  async function handleEditUnidade(id: string, data: IUnidadeInput) {
    try {
      await axiosInstance.put(`${API_UNIDADE}${id}`, data);
      await getUnidades();
    } catch (error) {
      console.error('Erro ao editar unidade:', error);
      throw error;
    }
  }

  async function handleDeleteUnidade(id: string) {
    try {
      await axiosInstance.delete(`${API_UNIDADE}${id}`);
      await getUnidades();
    } catch (error) {
      console.error('Erro ao deletar unidade:', error);
      throw error;
    }
  }

  useEffect(() => {
    getProfissional();
    getEspecialidades();
    getUnidades();
    getServicos();
  }, []);

  return (
    <GestaoEquipeContext.Provider
      value={{
        profissionais,
        especialidades,
        unidades,
        services,
        getProfissional,
        getEspecialidades,
        getUnidades,
        getServicos,
        handleAddProfissional,
        handleEditProfissional,
        handleDeleteProfissional,
        handleAddEspecialidade,
        handleEditEspecialidade,
        handleDeleteEspecialidade,
        handleAddServico,
        handleEditServico,
        handleDeleteServico,
        handleAddUnidade,
        handleEditUnidade,
        handleDeleteUnidade,
      }}
    >
      {children}
    </GestaoEquipeContext.Provider>
  );
}

export function useGestaoEquipe() {
  const context = useContext(GestaoEquipeContext);

  if (!context) {
    throw new Error(
      'useGestaoEquipe deve ser usado dentro de um GestaoEquipeProvider'
    );
  }

  return context;
}
