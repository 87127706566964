import { createContext, ReactNode, useContext, useState } from 'react';
import { IServico } from '../../interfaces/Servico/Servico';

interface ServicoContextType {
  servicos: IServico[];
  setServicos: React.Dispatch<React.SetStateAction<IServico[]>>;
}

const ServicoContext = createContext<ServicoContextType | undefined>(undefined);

interface ServicoProviderProps {
  children: ReactNode;
}

export const ServicoProvider = ({ children }: ServicoProviderProps) => {
  const [servicos, setServicos] = useState<IServico[]>([]);

  return (
    <ServicoContext.Provider value={{ servicos, setServicos }}>
      {children}
    </ServicoContext.Provider>
  );
};

export function useServico() {
  const context = useContext(ServicoContext);
  if (!context) {
    throw new Error('ServicoContext must be used within a ServicoProvider');
  }
  return context;
}

export default { useServico };
