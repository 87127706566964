import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { cssTransition, ToastContainer } from 'react-toastify';
import RouterConfig from '../router/router';

const App: React.FC = () => {
  return (
    <>
      <ToastContainer
        hideProgressBar={true}
        autoClose={2000}
        closeButton={false}
      />
      <Router>
        <RouterConfig />
      </Router>
    </>
  );
};

export default App;
